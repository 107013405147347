@import "_variables.scss";
@import "_fonts.scss";

/* icons */

//FAST ACCESS

[class^="icon_fast_access_"], [class*=" icon_fast_access_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $picto_fast_access !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon_blocs_"], [class*=" icon_blocs_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $picto_blocs !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon_faq_"], [class*=" icon_faq_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $picto_faq !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



//FAST ACCESS
.icon_fast_access_developper_son_entreprise_artisanale:before {
  content: "\e900";
}
.icon_fast_access_devenir_apprenti:before {
  content: "\e902";
}
.icon_fast_access_devenir_artisans:before {
  content: "\e904";
}
.icon_fast_access_trouver_un_artisan_pres_de_chez_soi:before {
  content: "\e906";
}

//BLOCS
.icon_blocs_arrow:before {
  content: "\e907";
}
.icon_blocs_calendar:before {
  content: "\e908";
}
.icon_blocs_formation_catalogue:before {
  content: "\e909";
}
.icon_blocs_formation_formalites:before {
  content: "\e910";
}
.icon_blocs_formation_mode_emploi:before {
  content: "\e911";
}
.icon_blocs_home:before {
  content: "\e912";
}

//FAQ
.icon_faq_artisans:before {
  content: "\e913";
}
.icon_faq_apprentissage:before {
  content: "\e914";
}