// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import "_variables.scss";
@import "_fonts.scss";
@import "_icons.scss";

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

//IE & EDGE STYLES
.ie,
.edge {
  overflow-x: hidden;
  .bloc-shadow {
    display: none;
  }
  section.fast-access {
    .container {
      .row {
        .title {
          height: 1px;
        }
        .fast-access-btns {
          .fast-access-btn {
            a {
              .background {
                @media #{$media_992_1199} {
                  padding-bottom: 30px;
                }
                i {
                  flex-basis: auto;
                }
                // @media #{$media_1200} {
                //   h1 {
                //     font-size: 18px;
                //   }
                // }
              }
            }
          }
        }
      }
    }
  }
  .blocs {
    .sections-title {
      height: 1px;
    }
    .link-all {
      height: 1px;
    }
  }
  section.reprise-entreprise {
    .content {
      .detail {
        .galerie {
          a {
            &:first-child {
              img {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

//GLOBAL

//COOKIE CONCENT
.cc-banner {
  z-index: 99999999 !important;
}

//SELECTS
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: $ubuntu_condensed;
}
/*hide original SELECT element:*/
.custom-select select {
  display: none !important;
}
.select-selected {
  background-color: $bg_select_styled;
  color: $c_select_styled;
  font-size: 18px;
  @include transition(all 0.2s ease-in);
  padding: 25px;
  font-family: $verdana_regular;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 32px;
  content: "\f078";
  background-color: $bg_select_styled_after;
  font: normal normal normal 14px/1 FontAwesome;
  color: $c_select_styled_after;
  padding: 25px 9px;
  @include transition(all 0.2s ease-in);
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  @include transform(rotate(-180deg));
  top: -10px;
}

/*style the items (options), including the selected item:*/
.select-items div {
  background-color: $bg_select_options;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
  font-family: $ubuntu_condensed;
  font-size: 14px;
  color: $c_select_options_li;
  padding: 8px 16px;
  margin: 0;
  cursor: pointer;
  @include transition(all 0.15s ease-in);
}

/*style items (options):*/
.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 166px;
  overflow-y: auto;
  background-color: $bg_select_options;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-items div:hover,
.same-as-selected {
  color: $c_select_options_li_hover !important;
  background-color: $bg_select_options_li_hover !important;
}

//SHADOW
.shadow1 {
  position: absolute;
  top: 100%;
  left: 50%;
  @include transform(translateX(-50%));
  background-image: $bgImg_shadow1;
  background-position: center center;
  background-repeat: no-repeat;
  height: 8px;
  width: 100%;
  z-index: 9999;
}
.bloc-shadow {
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(
    0px 0px,
    calc(100% - 5px) 0px,
    100% calc(100% - 32px),
    calc(100% - 32px) 100%,
    0px calc(100% - 5px)
  );
  overflow: hidden;
  .gradient {
    height: 100%;
    width: 100%;
    clip-path: polygon(
      0px 0px,
      calc(100% - 5px) 0px,
      100% calc(100% - 32px),
      calc(100% - 32px) 100%,
      0px calc(100% - 5px)
    );
    @include degrade_shadow;
    filter: blur(12px);
  }
}

//RETOUR BTN
.retour-btn {
  font-family: $ubuntu_condensed;
  font-size: $fs_retour_btn;
  font-weight: bold;
  color: $c_retour_btn;
  background-color: $bg_retour_btn;
  border: 1px solid $bord_retour_btn;
  padding: 5px 15px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  p {
    margin: 0;
  }
  &:hover,
  &:active,
  &:active:hover {
    color: $c_retour_btn_hover;
    background-color: $bg_retour_btn_hover;
  }
}

//LINKS
* a,
button {
  @include transition(all 0.3s ease-in-out);
  * {
    @include transition(all 0.3s ease-in-out);
  }
  &:hover,
  &:active,
  &:focus,
  &:active:visited,
  &:active:focus,
  &:active:hover {
    @include transition(all 0.3s ease-in-out);
    text-decoration: none;
    * {
      @include transition(all 0.3s ease-in-out);
    }
  }
}

//BACK TO TOP BTN
[id="back-to-top"] {
  cursor: pointer;
  position: fixed;
  bottom: 120px;
  right: 0;
  display: none;
  padding: 20px 25px !important;
  background-color: $grey !important;
  color: $white !important;
  font-size: 25px !important;
  border-radius: 0;
  border: 1px solid $white;
  border-right: 0;
  z-index: 5000000000000000;
  @include transition(all 0.2s ease-in);
  &:hover,
  &:active,
  &:active:hover {
    background-color: $red !important;
  }
}
//CONTENT
.content {
  .txt {
    h1 {
      font-family: $ubuntu_condensed;
      font-size: $fs_content_h1;
      color: $c_content_h1;
      margin-bottom: 30px;
      strong {
        font-weight: bold;
      }
    }
    h2 {
      font-family: $ubuntu_condensed;
      font-size: $fs_content_h2;
      color: $c_content_h2;
      margin-bottom: 30px;
      strong {
        font-weight: bold;
      }
    }
    h3 {
      font-family: $ubuntu_condensed;
      font-size: $fs_content_h3;
      color: $c_content_h3;
      margin-bottom: 30px;
      strong {
        font-weight: bold;
      }
    }
    h4 {
      font-family: $ubuntu_condensed;
      font-size: $fs_content_h4;
      color: $c_content_h4;
      margin-bottom: 30px;
      strong {
        font-weight: bold;
      }
    }
    h5 {
      font-family: $ubuntu_condensed;
      font-size: $fs_content_h5;
      color: $c_content_h5;
      margin-bottom: 30px;
      font-style: italic;
      strong {
        font-weight: bold;
      }
    }
    h6 {
      font-family: $ubuntu_condensed;
      font-size: $fs_content_h6;
      color: $c_content_h6;
      margin-bottom: 30px;
      font-style: italic;
      strong {
        font-weight: bold;
      }
    }
    p {
      font-family: $assistant_regular;
      font-size: $fs_content_p;
      color: $c_content_p;
      margin-bottom: 30px;
      strong {
        font-weight: bold;
      }
    }
    a {
      font-family: $assistant_regular;
      * {
        color: $c_content_a;
      }
      text-decoration: underline;
      &:hover,
      &:active,
      &:active:hover {
        text-decoration: none;
      }
    }
    ol {
      /* on initialise et nomme un compteur */
      counter-reset: ol_index;
      font-family: $assistant_regular;
      font-size: $fs_content_p;
      color: $c_content_p;
      padding-left: 0;
      margin-bottom: 15px;
      li {
        /* on incrémente le compteur à chaque nouveau li */
        counter-increment: ol_index;
        list-style-type: none;
        vertical-align: baseline;
        &:before {
          /* on affiche le compteur */
          content: counter(ol_index) " -";
          margin-right: 5px;
          vertical-align: baseline;
          //					color: $c_ol_before;
          //					font-size: $pfz_ol;
          //					font-family: $p_roboto_slab_bold;
        }
      }
    }
    ul {
      margin-bottom: 15px;
      margin-left: 20px;
      padding-left: 0px;
      font-family: $assistant_regular;
      font-size: $fs_content_p;
      color: $c_content_p;
      list-style: none;
      li {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: calc(100% + 15px);
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $c_content_p;
        }
      }
    }
    table {
      background-color: $bg_content_table;
      width: 100% !important;
      margin-bottom: 30px;
      * {
        word-break: normal;
      }
      th {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_resultats_table_th;
        text-transform: uppercase;
        padding: 10px;
        border-left: 2px solid #fff !important;
      }
      tr th {
        border-top: 2px solid #fff !important;
      }
      td {
        border: 1px solid $bord_content_table;
        padding: 10px;
        font-family: $assistant_regular;
      }
    }
    pre {
      white-space: normal;
      word-break: keep-all;
      border: 0;
      padding: 15px;
      margin-bottom: 30px;
    }
    hr {
      border-top: 1px solid;
      margin-bottom: 30px;
    }
    blockquote {
      padding: 0 0 0 20px;
      margin-bottom: 30px;
    }
  }
}

//NAVBAR - MENU
nav.main-nav {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  .header-top {
    background-color: $bg_header_top;
    .container {
      position: relative;
      .logo-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999999;
        .logo {
          display: inline-block;
          max-width: 100%;
          img {
            margin-left: -7px;
            display: block;
            max-width: 100%;
          }
        }
      }
      .header-actions-container {
        @include display-flex;
        @include flex-direction-row;
        @include justify-content-between;
        @include align-items(center);
        margin-left: -15px;
        > p {
          color: $c_header_top;
          font-family: $averia_light;
          font-size: $fs_header_top_p;
          margin-bottom: 0;
        }
        .header-actions {
          @include display-flex;
          @include flex-direction-row;
          @include justify-content-between;
          @include align-items(center);
          width: 46%;
          min-height: 50px;
          height: 1px;
          > a {
            color: $c_header_top;
            font-family: $pt_regular;
            text-transform: uppercase;
            font-size: $fs_header_top_a;
            transition: all 3s ease-in-out;
            i {
              margin-right: 15px;
            }
            p {
              display: inline-block;
              margin-bottom: 0;
              border-bottom: 1px solid transparent;
              line-height: 16px;
            }
            &:hover,
            &:active,
            &:active:hover {
              p {
                border-bottom: 1px solid white;
              }
            }
          }
          .header-separator {
            width: 2px;
            height: 21px;
            background-image: $bgImg_heaser_top_separator;
          }
          .dropdown {
            button {
              @include display-flex;
              @include flex-direction-row;
              @include justify-content-between;
              @include align-items(center);
              color: $c_header_top;
              font-family: $pt_regular;
              text-transform: uppercase;
              font-size: $fs_header_top_a;
              background-color: $bg_header_top_deconnexion;
              border: 0;
              padding: 0;
              p {
                margin: 0 15px;
              }
              &:hover,
              &:active,
              &:active:hover {
                p {
                  text-decoration: underline;
                }
              }
            }
            ul {
              top: 147%;
              right: 0;
              left: auto;
              z-index: 99999999;
              li {
                a {
                  p {
                    font-family: $ubuntu_condensed;
                    font-size: $fs_header_top_deconnexion;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .header-bottom {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999999;
    background-color: $bg_header_bottom;
    .nav-container {
      margin-left: -15px;
      .navbar-header {
      }
      .navbar-collapse {
        padding: 0;
        width: 100%;
        height: 100%;
        > ul {
          padding: 0;
          @include display-flex;
          @include flex-direction-row;
          @include justify-content-between;
          @include align-items(center);
          float: none;
          list-style: none;
          margin: 0;
          > li {
            a {
              padding: 44px 0;
              display: block;
              p {
                font-family: $ubuntu_condensed;
                font-size: $fs_header_bottom_p;
                color: $c_header_bottom_p;
                text-transform: uppercase;
                margin: 0;
                text-align: center;
              }
              &:hover,
              &:active,
              &:active:hover {
                text-decoration: none;
                p {
                  color: $c_header_bottom_p_hover;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  nav.main-nav {
    .header-top {
      .container {
        .header-actions-container {
          @include justify-content-end;
          > p {
            display: none;
          }
        }
      }
    }
  }
}

@media #{$media_0_991} {
  nav.main-nav {
    .header-top {
      .container {
        .header-actions-container {
          .header-actions {
            width: 58%;
          }
        }
      }
    }
  }
}

@media #{$media_0_767} {
  nav.main-nav {
    .header-top {
      .container {
        .header-actions-container {
          .header-actions {
            @include justify-content-end;
            width: 100%;
            > a {
              margin: 15px;
            }
          }
        }
      }
    }
    .header-bottom {
      .nav-container {
        .navbar-collapse {
          > ul {
            @include flex-direction;
            @include align-items-end;
            > li {
              a {
                padding: 15px;

                p {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_500} {
  nav.main-nav {
    .header-top {
      .container {
        .header-actions-container {
          .header-actions {
            > a {
              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

//SLIDER
section#slider-home-page {
  border: 0;
  height: 840px;
  margin: 0;
  position: relative;
  ul {
    li {
      position: relative;
      img {
        height: 840px;
        object-fit: cover;
      }
      .filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $bg_slider_filter;
      }
    }
  }
  .feder {
    position: absolute;
    bottom: 0;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 50;
    .container {
      .row {
        @include display-flex;
        @include align-items-end;
        p {
          color: $c_slider_feder_p;
          background-color: $bg_slider_feder_p;
          padding: 2px 15px;
          margin: 0;
          font-family: $ubuntu_condensed;
          letter-spacing: 4.4px;
          font-size: $fs_slider_feder_p;
          text-transform: uppercase;
          flex: 2 0 auto;
          text-align: justify;
        }
        img {
          max-width: 75px;
          flex: 0 0 auto;
        }
      }
    }
  }
  .home-fast-access {
    position: absolute;
    bottom: 120px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 99999;
  }
}

@media #{$media_0_1199} {
  section#slider-home-page {
    height: 600px;
    ul {
      li {
        img {
          height: 600px;
        }
      }
    }
    .feder {
      .container {
        .row {
          p {
            flex: auto;
          }
        }
      }
    }
    .home-fast-access {
      bottom: 85px;
    }
  }
}

@media #{$media_0_767} {
  section#slider-home-page {
    height: 500px;
    ul {
      li {
        img {
          height: 500px;
        }
      }
    }
    .feder {
      width: 100%;
    }
    .home-fast-access {
      width: 100%;
    }
  }
}

@media #{$media_0_500} {
  section#slider-home-page {
    height: 600px;
    ul {
      li {
        img {
          height: 600px;
        }
      }
    }
    .feder {
      .container {
        .row {
          p {
            text-align: left;
            letter-spacing: normal;
          }
        }
      }
    }
    .home-fast-access {
      width: 100%;
    }
  }
}

//ACCES RAPIDES
.home-fast-access {
  position: absolute;
  bottom: 120px;
  left: 50%;
  @include transform(translateX(-50%));
  z-index: 99999;
}

section.fast-access {
  .container {
    .row {
      .title {
        position: relative;
        width: 75%;
        background-color: $bg_fast_access_title;
        min-height: 71px;
        @include display-flex;
        @include flex-direction-row;
        @include justify-content-between;
        @include align-items(center);
        h1 {
          font-family: $ubuntu_condensed;
          font-size: $fs_fast_access_title;
          text-transform: uppercase;
          color: $c_fast_access_title;
          margin: 0;
          margin-left: 30px;
        }
        .angle {
          position: absolute;
          top: 0;
          left: 100%;
          width: 8.33333333%;
          width: calc(100% / 12);
          height: 100%;
          border-bottom: 71px solid $bg_fast_access_title;
          border-right: 71px solid transparent;
        }
      }
      .fast-access-btns {
        @include display-flex;
        @include flex-direction-row;
        @include justify-content-between;
        @include align-items(center);
        background-color: $bg_fast_access_btns;
        .fast-access-btn {
          flex: 1;
          padding: 40px 15px;
          &:nth-child(1) {
            a {
              .background {
                i,
                h1 {
                  color: $c_fast_access_btns_a_first;
                }
                .corner {
                  background-color: $bg_fast_access_btns_a_corner_first;
                }
              }
              &:hover,
              &:active,
              &:active:hover {
                .background {
                  background-color: $bg_fast_access_btns_a_hover_first;
                }
              }
            }
          }
          &:nth-child(2) {
            a {
              .background {
                i,
                h1 {
                  color: $c_fast_access_btns_a_second;
                }
                .corner {
                  background-color: $bg_fast_access_btns_a_corner_second;
                }
              }
              &:hover,
              &:active,
              &:active:hover {
                .background {
                  background-color: $bg_fast_access_btns_a_hover_second;
                }
              }
            }
          }
          &:last-child {
            background-color: $bg_fast_access_btns_last;
          }
          a {
            position: relative;
            display: block;
            position: relative;
            .background {
              @include display-flex;
              flex-wrap: wrap;
              min-height: 136px;
              padding-top: 14px;
              padding-left: 12px;
              padding-right: 20px;
              padding-bottom: 35px;
              background-color: $c_fast_access_btns_a;
              clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
              .corner {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $bg_fast_access_btns_a_corner;
                width: 29px;
                height: 29px;
              }
              p {
                font-family: $ubuntu_condensed;
                font-size: $fs_fast_access_btns_a_p;
                color: $c_fast_access_btns_a_p;
                text-transform: uppercase;
                flex-basis: 100%;
              }
              i {
                flex: 1;
                align-self: center;
                color: $c_fast_access_btns_a_h1;
                font-size: $fs_fast_access_btns_a_i;
                text-align: center;

                max-width: 76px;
              }
              h1 {
                flex: 2;
                align-self: center;
                font-family: $ubuntu_condensed;
                font-size: $fs_fast_access_btns_a_h1;
                color: $c_fast_access_btns_a_h1;
                text-transform: uppercase;
                margin: 0;
              }
            }
            .filter {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
              background-color: $bg_fast_access_btns_a_hover;
            }
            &:hover,
            &:active,
            &:active:hover {
              .background {
                background-color: $bg_fast_access_btns_a_hover;
                .corner {
                  background-color: $c_fast_access_btns_a_content_hover;
                }
                p {
                  color: $c_fast_access_btns_a_content_hover;
                }
                i {
                  color: $c_fast_access_btns_a_content_hover;
                }
                h1 {
                  color: $c_fast_access_btns_a_content_hover;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  section.fast-access {
    .container {
      .row {
        .title {
          h1 {
            font-size: $fs_fast_access_title_md;
          }
        }
        .fast-access-btns {
          .fast-access-btn {
            a {
              .background {
                h1 {
                  font-size: $fs_fast_access_btns_a_h1_md;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_991} {
  section.fast-access {
    .container {
      .row {
        .title {
          h1 {
            font-size: $fs_fast_access_title_sm;
          }
        }
        .fast-access-btns {
          .fast-access-btn {
            a {
              .background {
                i {
                  display: none;
                }
                h1 {
                  flex: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_767} {
  section.fast-access {
    .container {
      .row {
        .title {
          h1 {
            font-size: $fs_fast_access_title_xs;
          }
        }
        .fast-access-btns {
          flex-wrap: wrap;
          .fast-access-btn {
            width: 50%;
            padding: 10px 15px;
            flex: auto;
            a {
              .background {
                min-height: 1px;
                padding: 15px;
                p {
                  font-size: $fs_fast_access_btns_a_p_xs;
                }
                h1 {
                  font-size: $fs_fast_access_btns_a_h1_xs;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_500} {
  section.fast-access {
    .container {
      .row {
        .fast-access-btns {
          @include flex-direction;
          .fast-access-btn {
            width: 100%;
            a {
              .background {
                p {
                  font-size: $fs_fast_access_btns_a_p_xs;
                }
                h1 {
                  font-size: $fs_fast_access_btns_a_h1_xs;
                }
              }
            }
          }
        }
      }
    }
  }
}

//BLOCS
.blocs {
  .title-container {
    @include display-flex;
    @include flex-direction-row;
    @include align-items-end;
  }
  .sections-title {
    position: relative;
    width: 50%;
    @include display-flex;
    @include flex-direction-row;
    @include justify-content-between;
    @include align-items(center);
    z-index: 500;
    min-height: 71px;
    h1 {
      font-family: $ubuntu_condensed;
      font-size: $fs_blocs_h1;
      margin: 0;
      text-transform: uppercase;
      margin-left: 30px;
    }
    .angle {
      position: absolute;
      top: 0;
      left: 100%;
      width: 8.33333333%;
      width: calc(100% / 12);
      height: 100%;
      border-bottom: 71px solid;
      border-right: 71px solid transparent;
    }
  }
  .link-all {
    position: relative;
    width: calc(100% * 5 / 12);
    min-height: 58px;
    @include display-flex;
    @include flex-direction-row;
    @include justify-content-end;
    @include align-items(center);
    a {
      @include display-flex;
      @include flex-direction-row;
      @include justify-content-end;
      @include align-items(center);
      i {
        margin-right: 15px;
      }
      p {
        font-family: $ubuntu_condensed;
        font-size: $fs_blocs_link_all_p;
        margin: 0;
        text-transform: uppercase;
        margin-right: 50px;
      }
    }
    .angle {
      position: absolute;
      top: 0;
      left: 100%;
      width: 8.33333333%;
      width: calc(100% / 12);
      height: 100%;
      border-bottom: 58px solid;
      border-right: 58px solid transparent;
    }
  }
  .souligne {
    margin-top: -1px;
    width: 100%;
    border-bottom: 1px solid;
    border-right: 500px solid transparent;
    height: 1px;
    margin-bottom: 40px;
  }
  .rubriques {
    @include display-flex;
    @include flex-direction-row;
    @include justify-content-between;
    margin-left: -15px;
    margin-right: -15px;
    .bloc {
      margin: 0 15px;
      position: relative;
      .background {
        position: relative;
        clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
        height: 100%;
        @include display-flex;
        @include flex-direction;
        .rubrique-title {
          @include display-flex;
          @include align-items(center);
          @include justify-content-between;
          padding: 23px 20px 20px;
          h2 {
            font-family: $ubuntu_condensed;
            font-size: $fs_blocs_title_h2;
            margin: 0;
            text-transform: uppercase;
          }

          i {
            font-size: $fs_blocs_title_i;
          }
        }

        .rubrique-content {
          padding: 30px 10%;
          @include display-flex;
          @include align-items-end;
          @include justify-content-between;
          flex-wrap: wrap;
          flex-grow: 1;

          > p {
            font-family: $ubuntu_condensed;
            font-size: $fs_blocs_content_p;
            flex-basis: 100%;
            margin-bottom: 20px;
          }

          > i {
            font-size: $fs_blocs_content_calendar;
          }

          a {
            @include display-flex;
            @include align-items(center);
            @include justify-content-between;
            padding: 10px 45px 10px 20px;

            i {
              font-size: $fs_blocs_content_arrow;
            }

            p {
              font-family: $ubuntu_condensed;
              font-size: $fs_blocs_content_a_p;
              margin: 0;
              margin-left: 30px;
              text-transform: uppercase;
            }
          }

          &.photo {
            position: relative;
            background-color: none;
            padding: 25px 20px;
            @include justify-content-start;
            align-items: flex-end;
            min-height: 178px;
            overflow: hidden;

            .image {
              height: 100%;
              width: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
            }

            .filter {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: $bg_blocs_content_photo_filter;
            }

            .txt {
              z-index: 10;

              p {
                font-family: $ubuntu_condensed;
                font-size: $fs_blocs_content_photo_p;
                color: $bg_blocs_content_photo_txt;
                margin-bottom: 0;
                flex-basis: auto;
                text-transform: uppercase;

                &:nth-child(2) {
                  font-weight: bold;
                }
              }
            }
          }
        }

        .rubrique-footer {
          padding: 20px 10%;
          @include display-flex;
          @include align-items(center);
          @include justify-content(center);

          a {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
          }

          i {
            font-size: $fs_blocs_content_arrow;
            margin-right: 15px;
          }

          p {
            font-family: $ubuntu_condensed;
            text-transform: uppercase;
            font-size: $fs_blocs_footer_a_p;
            margin: 0;
          }
        }

        .corner {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 29px;
          height: 29px;
        }
      }

      &:hover,
      &:active,
      &:active:hover {
        .rubrique-content {
          &.photo {
            .image {
              transform: scale(1.3);
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  .blocs {
    .sections-title {
      width: 56%;

      h1 {
        font-size: $fs_blocs_h1_md;
      }
    }

    .link-all {
      width: calc(44% - 58px);

      a {
        p {
          font-size: $fs_blocs_link_md;
        }
      }
    }

    .rubriques {
      .bloc {
        .background {
          .rubrique-content {
            > i {
              flex: 1 0 10%;
            }

            a {
              flex: 1 0 60%;
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_991} {
  .blocs {
    .sections-title {
      width: 60%;

      h1 {
        font-size: $fs_blocs_h1_sm;
      }
    }

    .link-all {
      width: 40%;

      a {
        p {
          font-size: $fs_blocs_link_sm;
          margin-right: 15px;
        }
      }

      .angle {
        display: none;
      }
    }

    .rubriques {
      .bloc {
        .background {
          .rubrique-title {
            h2 {
              font-size: $fs_blocs_title_h2_sm;
            }

            i {
              font-size: $fs_blocs_title_i_sm;
            }
          }

          .rubrique-content {
            > p {
              font-size: $fs_blocs_content_p_sm;
            }

            > i {
              flex: 1 0 10%;
            }

            a {
              flex: 1 0 75%;

              p {
                font-size: $fs_blocs_content_a_p_sm;
              }
            }

            &.photo {
              .txt {
                p {
                  font-size: $fs_blocs_content_photo_p_sm;
                }
              }
            }
          }

          .rubrique-footer {
            p {
              font-size: $fs_blocs_footer_a_p_sm;
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_767} {
  .blocs {
    .title-container {
      flex-wrap: wrap;
    }

    .sections-title {
      width: calc(100% - 71px);

      h1 {
        font-size: $fs_blocs_h1_xs;
      }
    }

    .link-all {
      @include justify-content-start;
      width: 100%;
      padding: 15px;

      a {
        p {
          font-size: $fs_blocs_h1_xs;
        }
      }

      .angle {
        display: none;
      }
    }

    .souligne {
      border-right-width: 1px;
    }

    .rubriques {
      @include flex-direction;

      .bloc {
        width: calc(100% - 30px) !important;

        & + .bloc {
          margin-top: 15px;
        }

        & + .accroche {
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .background {
          .rubrique-title {
            h2 {
              font-size: $fs_blocs_title_h2_xs;
            }

            i {
              font-size: $fs_blocs_title_i_xs;
            }
          }

          .rubrique-content {
            > i {
              flex: 1 0 10%;
            }

            a {
              flex: 1 0 75%;

              i {
              }

              p {
              }
            }
          }
        }
      }
    }
  }
}

//BLOC DEVENIR ARTISAN
section.bloc-devenir-artisan {
  background-color: $bg_section_devenir_artisan;
  padding-top: 55px;
  padding-bottom: 72px;

  .container {
    .sections-title {
      background-color: $bg_bloc_devenir_artisan_title;

      h1 {
        color: $c_bloc_devenir_artisan_title;
      }

      .angle {
        border-bottom-color: $bord_bloc_devenir_artisan_title;
      }
    }

    .souligne {
      border-bottom-color: $bord_bloc_devenir_artisan_souligne;
    }

    .rubriques {
      flex-wrap: wrap;
      margin: 0;
    }

    .bloc {
      margin: 0;
      &.bloc-reunion-information {
        width: calc(100% * 4 / 12);
        .background {
          background-color: $bg_bloc_reunion_information_bloc;

          .rubrique-title {
            h2 {
              color: $c_bloc_reunion_information_rubrique_titre;
            }
          }

          .rubrique-content {
            background-color: $bg_blocs_reunion_information_rubrique_content;

            > p {
              color: $c_bloc_reunion_information_rubrique_content;
              
            }

            > i {
              color: $c_bloc_reunion_information_rubrique_content;
            }

            a {
              width: 74%;
              background-color: $bg_bloc_reunion_information_rubrique_content_a;

              i {
                color: $c_bloc_reunion_information_rubrique_content_a;
              }

              p {
                color: $c_bloc_reunion_information_rubrique_content_a;
                font-size: 20px;
              }

              &:hover,
              &:active,
              &:active:hover {
                background-color: darken($bg_bloc_reunion_information_rubrique_content_a, 20%);

                i {
                  color: darken($c_bloc_reunion_information_rubrique_content_a, 20%);
                }
                p {
                  color: darken($c_bloc_reunion_information_rubrique_content_a, 20%);
                }
              }
            }
          }
          .rubrique-footer {
            a {
              i {
                color: $c_bloc_reunion_information_rubrique_footer;
              }
              p {
                color: $c_bloc_reunion_information_rubrique_footer;
              }
              &:hover,
              &:active,
              &:active:hover {
                i {
                  color: darken($c_bloc_reunion_information_rubrique_footer, 5%);
                }
                p {
                  color: darken($c_bloc_reunion_information_rubrique_footer, 5%);
                }
              }
            }
          }
          .corner {
            background-color: $bg_bloc_reunion_information_corner;
          }
        }
      }
      &.bloc-reunion-iframe {
        width: calc(100% * 4 / 12 - 15px);
        .background {
          background-color: $bg_bloc_reprise_entreprise_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_reprise_entreprise_rubrique_titre;
            }
            i {
              color: $c_bloc_reprise_entreprise_rubrique_titre_i;
            }
          }
          .rubrique-content {
            padding: 0;
            iframe {
              height: 100%;
              width: 100%;
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_reprise_entreprise_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_reprise_entreprise_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_reprise_entreprise_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_reprise_entreprise_bloc_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              i {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
            }
            .rubrique-content {
              > p {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              > i {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              &.photo {
                .txt {
                  p {
                    color: $c_bloc_reprise_entreprise_bloc_content_hover;
                  }
                }
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              p {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_reprise_entreprise_bloc_content_hover;
            }
          }
        }
      }
      &.bloc-reprise-entreprise {
        width: calc(100% * 4 / 12 - 15px);
        .background {
          background-color: $bg_bloc_reprise_entreprise_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_reprise_entreprise_rubrique_titre;
            }
            i {
              color: $c_bloc_reprise_entreprise_rubrique_titre_i;
            }
          }
          .rubrique-content {
            > p {
              color: $c_bloc_reprise_entreprise_rubrique_content;
            }
            > i {
              color: $c_bloc_reprise_entreprise_rubrique_content;
            }
            &.photo {
              .image {
                background-image: $bgImg_blocs_content_photo;
              }
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_reprise_entreprise_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_reprise_entreprise_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_reprise_entreprise_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_reprise_entreprise_bloc_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              i {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
            }
            .rubrique-content {
              > p {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              > i {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              &.photo {
                .txt {
                  p {
                    color: $c_bloc_reprise_entreprise_bloc_content_hover;
                  }
                }
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
              p {
                color: $c_bloc_reprise_entreprise_bloc_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_reprise_entreprise_bloc_content_hover;
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_991} {
  section.bloc-devenir-artisan {
    .container {
      .bloc {
        &.bloc-reunion-information {
          .background {
            .rubrique-content {
              > i {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_767} {
  section.bloc-devenir-artisan {
    .container {
      .rubriques {
        margin: 0 -15px;
      }
      .bloc {
        margin: 0 15px;
        &.bloc-reunion-iframe {
          width: calc(100% - 30px);
          margin: 0 15px 15px;
        }
      }
    }
  }
}

// BLOC DEVENIR APPRENTI
section.bloc-devenir-apprenti {
  background-image: $bgImg_section_devenir_apprenti;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 55px;
  padding-bottom: 60px;
  position: relative;
  .container {
    .sections-title {
      background-color: $bg_bloc_devenir_apprenti_title;
      h1 {
        color: $c_bloc_devenir_apprenti_title;
      }
      .angle {
        border-bottom-color: $bord_bloc_devenir_apprenti_title;
      }
    }
    .souligne {
      border-bottom-color: $bord_bloc_devenir_apprenti_souligne;
    }
    .bloc {
      width: calc(100% * 4 / 12);
      &.bloc-apprentissage {
        .background {
          background-color: $bg_bloc_apprentissage_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_apprentissage_rubrique_titre;
            }
          }
          .rubrique-content {
            > p {
              color: $c_bloc_apprentissage_rubrique_content;
            }
            > i {
              color: $c_bloc_apprentissage_rubrique_content;
            }
            &.photo {
              .image {
                background-image: $bgImg_blocs_apprentissage_content_photo;
              }
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_apprentissage_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_apprentissage_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_apprentissage_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_apprentissage_bloc_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_apprentissage_bloc_content_hover;
              }
              i {
                color: $c_bloc_apprentissage_bloc_content_hover;
              }
            }
            .rubrique-content {
              > p {
                color: $c_bloc_apprentissage_bloc_content_hover;
              }
              > i {
                color: $c_bloc_apprentissage_bloc_content_hover;
              }
              &.photo {
                .txt {
                  p {
                    color: $c_bloc_apprentissage_bloc_content_hover;
                  }
                }
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_apprentissage_bloc_content_hover;
              }
              p {
                color: $c_bloc_apprentissage_bloc_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_apprentissage_bloc_content_hover;
            }
          }
        }
      }
      &.bloc-hebergement {
        .background {
          background-color: $bg_bloc_hebergement_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_hebergement_rubrique_titre;
            }
            i {
              color: $c_bloc_hebergement_rubrique_titre_i;
            }
          }
          .rubrique-content {
            > p {
              color: $c_bloc_hebergement_rubrique_content;
            }
            > i {
              color: $c_bloc_hebergement_rubrique_content;
            }
            &.photo {
              .image {
                background-image: $bgImg_blocs_hebergement_content_photo;
              }
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_hebergement_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_hebergement_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_hebergement_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_hebergement_rubrique_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_hebergement_rubrique_content_hover;
              }
              i {
                color: $c_bloc_hebergement_rubrique_content_hover;
              }
            }
            .rubrique-content {
              > p {
                color: $c_bloc_hebergement_rubrique_content_hover;
              }
              > i {
                color: $c_bloc_hebergement_rubrique_content_hover;
              }
              &.photo {
                .txt {
                  p {
                    color: $c_bloc_hebergement_rubrique_content_hover;
                  }
                }
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_hebergement_rubrique_content_hover;
              }
              p {
                color: $c_bloc_hebergement_rubrique_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_hebergement_rubrique_content_hover;
            }
          }
        }
      }
    }
    .accroche {
      width: calc(100% * 4 / 12);
      margin: 0 15px;
      border: 1px solid $bord_bloc_accroche;
      text-align: center;
      padding: 15px;
      @include display-flex;
      @include flex-direction;
      @include align-items(center);
      @include justify-content(center);
      p {
        font-family: $ubuntu_condensed;
        font-size: $fs_blocs_accroche_p;
        color: white;
        text-transform: uppercase;
        margin: 0;
        &:first-child {
          font-weight: bold;
          margin-bottom: 30px;
        }
      }
    }
  }
}
@media #{$media_0_991} {
  section.bloc-devenir-apprenti {
    .container {
      .accroche {
        p {
          font-size: $fs_blocs_accroche_p_sm;
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.bloc-devenir-apprenti {
    .container {
      .accroche {
        width: calc(100% - 30px);
      }
    }
  }
}
// BLOC DEVELOPPER SON ENTREPRISE ARTISANALE
section.bloc-developper-artisanale {
  background-color: $bg_developper_artisanale;
  padding-top: 55px;
  padding-bottom: 30px;
  .container {
    .sections-title {
      background-color: $bg_bloc_developper_artisanale_title;
      h1 {
        color: $c_bloc_developper_artisanale_title;
      }
      .angle {
        border-bottom-color: $bord_bloc_developper_artisanale_title;
      }
    }
    .souligne {
      border-bottom-color: $bord_bloc_developper_artisanale_souligne;
    }
    .bloc {
      width: calc(100% * 4 / 12);
      &.bloc-formalite {
        .background {
          background-color: $bg_bloc_formalite_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_formalite_rubrique_titre;
            }
            i {
              color: $c_bloc_formalite_rubrique_titre_i;
            }
          }
          .rubrique-content {
            > p {
              color: $c_bloc_formalite_rubrique_content;
            }
            > i {
              color: $c_bloc_formalite_rubrique_content;
            }
            &.photo {
              .image {
                background-image: $bgImg_blocs_formalite_content_photo;
              }
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_formalite_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_formalite_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_formalite_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_formalite_bloc_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_formalite_bloc_content_hover;
              }
              i {
                color: $c_bloc_formalite_bloc_content_color_hover;
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_formalite_bloc_content_color_hover;
              }
              p {
                color: $c_bloc_formalite_bloc_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_formalite_bloc_content_color_hover;
            }
          }
        }
      }
      &.bloc-mode-emploi {
        .background {
          background-color: $bg_bloc_mode_emploi_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_mode_emploi_rubrique_titre;
            }
            i {
              color: $c_bloc_mode_emploi_rubrique_titre_i;
            }
          }
          .rubrique-content {
            > p {
              color: $c_bloc_mode_emploi_rubrique_content;
            }
            > i {
              color: $c_bloc_mode_emploi_rubrique_content;
            }
            &.photo {
              .image {
                background-image: $bgImg_blocs_mode_emploi_content_photo;
              }
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_mode_emploi_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_mode_emploi_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_mode_emploi_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_mode_emploi_bloc_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_mode_emploi_bloc_content_hover;
              }
              i {
                color: $c_bloc_mode_emploi_bloc_content_color_hover;
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_mode_emploi_bloc_content_color_hover;
              }
              p {
                color: $c_bloc_mode_emploi_bloc_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_mode_emploi_bloc_content_color_hover;
            }
          }
        }
      }
      &.bloc-catalogue-formation {
        .background {
          background-color: $bg_bloc_catalogue_formation_bloc;
          .rubrique-title {
            h2 {
              color: $c_bloc_catalogue_formation_rubrique_titre;
            }
            i {
              color: $c_bloc_catalogue_formation_rubrique_titre_i;
            }
          }
          .rubrique-content {
            > p {
              color: $c_bloc_catalogue_formation_rubrique_content;
            }
            > i {
              color: $c_bloc_catalogue_formation_rubrique_content;
            }
            &.photo {
              .image {
                background-image: $bgImg_blocs_catalogue_formation_content_photo;
              }
            }
          }
          .rubrique-footer {
            padding: 20px;
            @include justify-content-start;
            i {
              color: $c_bloc_catalogue_formation_rubrique_footer_arrow;
            }
            p {
              color: $c_bloc_catalogue_formation_rubrique_footer;
            }
          }
          .corner {
            background-color: $bg_bloc_catalogue_formation_corner;
          }
          &:hover,
          &:active,
          &:active:hover {
            background-color: $bg_bloc_catalogue_formation_bloc_hover;
            .rubrique-title {
              h2 {
                color: $c_bloc_catalogue_formation_bloc_content_hover;
              }
              i {
                color: $c_bloc_catalogue_formation_bloc_content_color_hover;
              }
            }
            .rubrique-footer {
              i {
                color: $c_bloc_catalogue_formation_bloc_content_color_hover;
              }
              p {
                color: $c_bloc_catalogue_formation_bloc_content_hover;
              }
            }
            .corner {
              background-color: $c_bloc_catalogue_formation_bloc_content_color_hover;
            }
          }
        }
      }
    }
  }
}
// BLOC ACTUALITES
section.page.bloc-actualites {
  background-color: $bg_actualites_home;
  &.home {
    margin-top: 40px !important;
    .container {
      .hr {
        margin-top: 40px !important;
      }
    }
  }
  .container {
    .sections-title {
      background-color: $bg_bloc_actualites_home_title;
      h1 {
        color: $c_bloc_actualites_home_title;
      }
      .angle {
        border-bottom-color: $bord_bloc_actualites_home_title;
      }
    }
    .link-all {
      background-color: $bg_blocs_actualites_home_link_all;
      a {
        p {
          color: $c_blocs_actualites_home_link_all_p;
        }
        i {
          color: $c_blocs_actualites_home_link_all_i;
        }
        &:hover,
        &:active,
        &:active:hover {
          p {
            color: darken($c_blocs_actualites_home_link_all_p, 20%);
          }
          i {
            color: darken($c_blocs_actualites_home_link_all_i, 20%);
          }
        }
      }
      .angle {
        border-bottom-color: $bord_blocs_actualites_home_link_all;
      }
    }
    .souligne {
      border-bottom-color: $bord_bloc_actualites_home_souligne;
    }
    .actus {
      @include display-flex;
      @include flex-direction-row;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: 40px;
      .actu {
        width: calc(100% * 3 / 12 - 30px);
        margin: 0 15px;
        position: relative;
        .actu-container {
          position: relative;
          height: 100%;
          @include display-flex;
          @include flex-direction;
          .rubrique-content {
            position: relative;
            background-color: none;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 25px 20px;
            @include justify-content-start;
            align-items: flex-end;
            min-height: 178px;
            overflow: hidden;
            .image {
              height: 100%;
              width: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
            }
            .filter {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              p {
                font-family: $ubuntu_condensed;
                font-size: $fs_blocs_actualites_home_date;
                margin: 10px;
              }
            }
          }
          .rubrique-footer {
            position: relative;
            flex-wrap: wrap;
            flex-grow: 1;
            .txt-background {
              padding: 30px 10px 10px;
              clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
              @include display-flex;
              @include flex-direction;
              @include justify-content-between;
              height: 100%;
              p {
                font-family: $ubuntu_condensed;
                font-size: $fs_blocs_actualites_home_p;
                margin: 0;
                &:first-child {
                  text-transform: uppercase;
                  font-size: $fs_blocs_actualites_home_p;
                  margin-bottom: 25px;
                }
              }
              .corner {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 29px;
                height: 29px;
                z-index: 50;
              }
            }
          }
          &:hover,
          &:active,
          &:active:hover {
            .rubrique-content {
              .image {
                transform: scale(1.3);
              }
            }
          }
        }
      }
      .pagination-container {
        flex-basis: 100%;
        margin: 0 15px;
        .pagination {
          width: 100%;
          margin: 0;
          margin-bottom: 30px;
          .link,
          .active {
            display: none;
          }
          .next,
          .prev {
            i {
              display: none;
            }
            a {
              visibility: hidden;
              &:after {
                visibility: visible;
                display: block;
                background-color: $bg_actualites_pagination_btn;
                color: $c_actualites_pagination_btn;
                font-family: $ubuntu_condensed;
                font-size: $fs_actualites_pagination_btn;
                font-weight: bold;
                text-transform: uppercase;
                padding: 15px 30px;
              }
            }
          }
          .next {
            float: right;
            a {
              &:after {
                content: "Actus + Récentes";
              }
            }
          }
          .prev {
            float: left;
            a {
              &:after {
                content: "Actus + Anciennes";
              }
            }
          }
        }
      }
    }
    .hr {
      width: 100%;
      height: 1px;
      background-color: $bg_blocs_actualites_home_hr;
      margin-bottom: 40px;
    }
  }
}
@media #{$media_0_991} {
  section.page.bloc-actualites {
    .container {
      .actus {
        flex-wrap: wrap;
        .actu {
          width: calc(50% - 30px);
          margin-bottom: 30px;
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.page.bloc-actualites {
    .container {
      .actus {
        @include flex-direction;
        .actu {
          width: calc(100% - 30px);
          margin-bottom: 0;
          & + .actu {
            margin-top: 15px;
          }
        }
        .pagination-container {
          .pagination {
            @include display-flex;
            @include justify-content-between;
            flex-wrap: wrap;
            .link,
            .active {
            }
            .next,
            .prev {
              display: block;
              width: 100%;
              i {
              }
              a {
                width: 100%;
                &:after {
                  font-size: 20px;
                }
              }
            }
            .next {
              a {
                &:after {
                }
              }
            }
            .prev {
              a {
                &:after {
                }
              }
            }
          }
        }
      }
    }
  }
}
// PARTENAIRES
section.partenaires {
  padding-top: 30px;
  padding-bottom: 30px;
  .container {
    .souligne {
      position: relative;
      height: 1px;
      width: 100%;
      border-bottom: 1px solid;
      border-bottom-color: $bord_partenaires_souligne;
      border-right: 500px solid transparent;
      margin-bottom: 30px;
      h1 {
        position: absolute;
        top: 50%;
        left: 0;
        @include transform(translateY(-50%));
        margin: 0;
        text-transform: uppercase;
        background-color: $bg_partenaires_h1;
        padding-right: 40px;
        font-family: $ubuntu_condensed;
        font-size: $fs_partenaires_h1;
        color: $c_partenaires_h1;
      }
    }
    .logos {
      @include display-flex;
      @include align-items(center);
      @include justify-content-between;
      flex-wrap: wrap;
      margin: auto -15px;
      a {
        padding: 15px;
        &:hover,
        &:active,
        &:active:hover {
          img {
            @include drop-shadow($shadow_partenaires);
          }
        }
      }
    }
  }
}
@media #{$media_0_500} {
  section.partenaires {
    .container {
      .souligne {
        border-right-width: 200px;
        h1 {
          font-size: $fs_partenaires_h1_xs;
        }
      }
      .logos {
        @include flex-direction;
      }
    }
  }
}
// FRISE
.frise {
  background-color: $bg_frise;
  height: 4px;
  width: 100%;
  .container {
    @include display-flex;
    height: 100%;
    .vert {
      background-color: $bg_frise_vert;
      flex-grow: 1;
      height: 100%;
    }
    .bleu {
      background-color: $bg_frise_bleu;
      flex-grow: 1;
      height: 100%;
    }
    .jaune {
      background-color: $bg_frise_jaune;
      flex-grow: 1;
      height: 100%;
    }
  }
}
// FOOTER HOME
footer.footer-home {
  background-color: $bg_footer_home;
  padding-top: 90px;
  padding-bottom: 100px;
  .newsletter-container {
    h1 {
      font-family: $assistant_regular;
      font-weight: bold;
      color: $c_footer_home_h1;
      font-size: $fs_footer_home_h1;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 10px;
    }
    h2 {
      font-family: $assistant_regular;
      color: $c_footer_home_h2;
      font-size: $fs_footer_home_h2;
      margin: 0;
      margin-bottom: 35px;
    }
    .newsletter {
      @include display-flex;
      @include flex-direction-row;
      @include align-items(center);
      @include justify-content-between;
      form {
        @include display-flex;
        // @include flex-direction-row; // @include align-items(center); // @include justify-content-between; // flex-grow: 7;
        width: calc(100% * 7 / 12);
        input {
          flex: 1 0 8em;
          width: calc(100% * 4 / 7);
          background-color: $bg_newsletter_input;
          border: 0;
          border-radius: 0;
          padding: 25px;
          font-family: $verdana_regular;
          color: $c_newsletter_input;
          height: auto;
          &::placeholder {
            font-style: italic;
            color: $c_newsletter_input_placeholder;
          }
          &:focus {
            box-shadow: none;
          }
        }
        button {
          flex: 0 0 auto;
          white-space: normal;
          position: relative;
          width: calc(100% * 3 / 7);
          border-radius: 0;
          border: 0;
          padding: 0;
          background-color: transparent;
          .background {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
            padding: 20px;
            background-color: $bg_newsletter_button;
            height: 100%;
            i {
              margin-right: 15px;
              color: $c_newsletter_button_i;
              font-size: $fs_newsletter_button_i;
            }
            p {
              margin: 0;
              color: $c_newsletter_button_p;
              font-size: $fs_newsletter_button_p;
              font-family: $quicksand_bold;
            }
            .corner {
              position: absolute;
              bottom: 0;
              right: 0;
              background-color: $bg_newsletter_button_corner;
              width: 29px;
              height: 29px;
            }
          }
          &:hover,
          &:active,
          &:active:hover {
            .background {
              background-color: $bg_newsletter_button_hover;
              i {
                color: $c_newsletter_button_content_hover;
              }
              p {
                color: $c_newsletter_button_content_hover;
              }
              .corner {
                background-color: $c_newsletter_button_content_hover;
              }
            }
          }
        }
      }
      img {
        width: auto;
      }
    }
  }
  nav {
    padding-top: 60px;
    .links {
      @include display-flex;
      @include align-items(center);
      @include justify-content-between;
      padding: 35px 0;
      a {
        p {
          font-family: $ubuntu_condensed;
          text-transform: uppercase;
          font-size: $fs_footer_home_a;
          color: $c_footer_home_a;
          margin: 0;
        }
        &:hover,
        &:active,
        &:active:hover {
          p {
            color: $c_footer_home_a_hover;
          }
        }
      }
    }
    hr {
      width: 100%;
      height: 1px;
      border: 0;
      border-bottom: 1px solid $bord_footer_home_hr;
      background-color: $bg_footer_home_hr;
      margin: 0;
    }
  }
}
@media #{$media_0_991} {
  footer.footer-home {
    .newsletter-container {
      .newsletter {
        flex-wrap: wrap;
        form {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
    nav {
      .links {
        flex-wrap: wrap;
        margin: auto -15px;
        a {
          padding: 7px;
        }
      }
    }
  }
}
@media #{$media_0_500} {
  footer.footer-home {
    padding-bottom: 40px;
    .newsletter-container {
      .newsletter {
        flex-wrap: wrap;
        form {
          width: 100%;
          margin-bottom: 30px;
          flex-direction: column;
          input {
            width: 100%;
            flex-basis: auto;
          }
          button {
            width: 100%;
          }
        }
      }
    }
    nav {
      .links {
        @include flex-direction;
        @include align-items-start;
        flex-wrap: wrap;
        margin: 0;
        a {
          padding: 7px 0;
        }
      }
    }
  }
}
// HEADER PAGE INTERNES
.header-img {
  width: 100%;
  height: 480px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  &.trouver-artisan {
    background-image: $bgImg_header_trouver_artisan;
  }
  &.actus {
    background-image: $bgImg_header_actus;
  }
  &.general {
    background-image: $bgImg_header_general;
  }
  &.devenir-artisan {
    background-image: $bgImg_header_devenir_artisan;
  }
  &.recrute {
    background-image: $bgImg_header_recrute;
  }
  &.devenir-apprenti {
    background-image: $bgImg_header_devenir_apprenti;
  }
  &.developper-entreprise {
    background-image: $bgImg_header_developper_entreprise;
  }
}
// HEADER PAGE INTERNES
@media #{$media_0_1199} {
  .header-img {
    height: 280px;
  }
}
// HEADER PAGE INTERNES
@media #{$media_0_767} {
  .header-img {
    height: 150px;
  }
}
// TROUVER UN ARTISAN
section.page {
  padding: 0 !important;
  margin: 0 !important;
  &.jaune {
    .sections-title {
      background-color: $bg_page_jaune_color !important;
      h1 {
        color: $c_page_jaune_txt;
      }
      .angle {
        border-bottom-color: $bg_page_jaune_color !important;
      }
    }
    .link-all {
      background-color: $bg_page_jaune_color_black !important;
      a {
        p {
          color: $c_page_jaune_txt;
        }
        i {
          color: $c_page_jaune_txt;
        }
        &:hover,
        &:active,
        &:active:hover {
          p {
            color: darken($c_page_jaune_txt, 20%);
          }
          i {
            color: darken($c_page_jaune_txt, 20%);
          }
        }
      }
      .angle {
        border-bottom-color: $bg_page_jaune_color_black !important;
      }
    }
    .souligne {
      border-bottom-color: $bg_page_jaune_color !important;
    }
    .form-section {
      .form-title {
        color: $bg_page_jaune_color;
      }
      form {
        .custom-select {
          .select-selected {
            color: $bg_page_jaune_color;
            &:after {
              color: $bg_page_jaune_color;
            }
          }
          .select-items {
            div {
              background-color: $bg_page_jaune_color;
              &:hover,
              &:active,
              &:active:hover {
                color: $bg_page_jaune_color !important;
              }
            }
          }
          .same-as-selected {
            color: $bg_page_jaune_color !important;
          }
        }
        button {
          .background {
            background-color: $bg_page_jaune_color;
          }
          &:hover,
          &:active,
          &:active:hover {
            .background {
              background-color: $c_page_jaune_txt;
              i {
                color: $bg_page_jaune_color;
              }
              p {
                color: $bg_page_jaune_color;
              }
              .corner {
                background-color: $bg_page_jaune_color;
              }
            }
          }
        }
      }
      .epci-choice {
        p {
          color: $bg_page_jaune_color;
        }
      }
    }
    .resultats {
      .nb {
        color: $bg_page_jaune_color;
      }
      table {
        th {
          background-color: $bg_page_jaune_color;
          color: $c_page_jaune_txt;
        }
        tbody {
          a {
            i {
              color: $bg_page_jaune_color;
            }
          }
        }
      }
    }
    .content {
      .titre {
        color: $bg_page_jaune_color;
      }
      .txt {
        a {
          color: $bg_page_jaune_color;
        }
        ol {
          li {
            &:before {
              color: $bg_page_jaune_color;
            }
          }
        }
        ul {
          li {
            &:before {
              background-color: $bg_page_jaune_color;
            }
          }
        }
        hr {
          border-top-color: $bg_page_jaune_color;
        }
        table {
          tbody {
            a {
              i {
                color: $bg_page_jaune_color;
              }
            }
          }
          th {
            background-color: $bg_page_jaune_color;
            color: $c_page_jaune_txt;
            background-color: $bg_page_jaune_color;
            border-top: 1px solid $bg_page_jaune_color;
            border-right: 1px solid $bg_page_jaune_color;
            border-bottom: 1px solid $bg_page_jaune_color;
            &:first-child {
              border-left-color: $bg_page_jaune_color;
            }
          }
          tr:first-child th {
            border-top-color: $bg_page_jaune_color;
          }
        }
      }
      .themes {
        a {
          &.active {
            &:before {
              background-color: $bg_page_jaune_color;
            }
          }
        }
      }
      .questions {
        .question-response {
          &.element-actif {
            > p {
              color: $bg_page_jaune_color !important;
            }
          }
        }
        > p {
        }
        .panel-group {
          .panel {
            .panel-heading {
              a {
                &:hover,
                &:active:hover {
                  p {
                    color: $bg_page_jaune_color;
                  }
                }
              }
            }
            .panel-collapse {
              .panel-body {
                border-top-color: $bg_page_jaune_color;
              }
            }
            &.contact-panel {
              a {
                color: $bg_page_jaune_color;
                &:hover,
                &:active,
                &:active:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .actus {
      .actu {
        .actu-container {
          .rubrique-content {
            .image {
            }
            .filter {
              background-color: rgba($bg_page_jaune_color, 0.2);
              p {
                color: $c_page_jaune_txt;
              }
            }
          }
          .rubrique-footer {
            .txt-background {
              background-color: $c_page_jaune_txt;
              p {
                color: $bg_page_jaune_color;
                &:first-child {
                }
              }
              .corner {
                background-color: $bg_page_jaune_color;
              }
            }
          }
          &:hover,
          &:active,
          &:active:hover {
            .rubrique-content {
              .image {
              }
              .filter {
                background-color: rgba(darken($bg_page_jaune_color, 5%), 0.2);
                p {
                  color: darken($c_page_jaune_txt, 5%);
                }
              }
            }
            .rubrique-footer {
              .txt-background {
                background-color: $bg_page_jaune_color;
                p {
                  color: $c_page_jaune_txt;
                }
                .corner {
                  background-color: $c_page_jaune_txt;
                }
              }
            }
          }
        }
      }
    }
  }
  &.vert {
    .sections-title {
      background-color: $bg_page_vert_color !important;
      h1 {
        color: $c_page_vert_txt;
      }
      .angle {
        border-bottom-color: $bg_page_vert_color !important;
      }
    }
    .link-all {
      background-color: $bg_page_vert_color_black !important;
      a {
        p {
          color: $c_page_vert_txt;
        }
        i {
          color: $c_page_vert_txt;
        }
        &:hover,
        &:active,
        &:active:hover {
          p {
            color: darken($c_page_vert_txt, 20%);
          }
          i {
            color: darken($c_page_vert_txt, 20%);
          }
        }
      }
      .angle {
        border-bottom-color: $bg_page_vert_color_black !important;
      }
    }
    .souligne {
      border-bottom-color: $bg_page_vert_color !important;
    }
    .form-section {
      .form-title {
        color: $bg_page_vert_color;
      }
      form {
        .custom-select {
          .select-selected {
            color: $bg_page_vert_color;
            &:after {
              color: $bg_page_vert_color;
            }
          }
          .select-items {
            div {
              background-color: $bg_page_vert_color;
              &:hover,
              &:active,
              &:active:hover {
                color: $bg_page_vert_color !important;
              }
            }
          }
          .same-as-selected {
            color: $bg_page_vert_color !important;
          }
        }
        button {
          .background {
            background-color: $bg_page_vert_color;
          }
          &:hover,
          &:active,
          &:active:hover {
            .background {
              background-color: $c_page_vert_txt;
              i {
                color: $bg_page_vert_color;
              }
              p {
                color: $bg_page_vert_color;
              }
              .corner {
                background-color: $bg_page_vert_color;
              }
            }
          }
        }
      }
      .epci-choice {
        p {
          color: $bg_page_vert_color;
        }
      }
    }
    .resultats {
      .nb {
        color: $bg_page_vert_color;
      }
      table {
        thead {
          th {
            background-color: $bg_page_vert_color;
            color: $c_page_vert_txt;
          }
        }
        tbody {
          a {
            i {
              color: $bg_page_vert_color;
            }
          }
        }
      }
    }
    .content {
      .titre {
        color: $bg_page_vert_color;
      }
      .txt {
        a {
          color: $bg_page_vert_color;
        }
        ol {
          li {
            &:before {
              color: $bg_page_vert_color;
            }
          }
        }
        ul {
          li {
            &:before {
              background-color: $bg_page_vert_color;
            }
          }
        }
        hr {
          border-top-color: $bg_page_vert_color;
        }
        table {
          tbody {
            a {
              i {
                color: $bg_page_vert_color;
              }
            }
          }
          th {
            background-color: $bg_page_vert_color;
            color: $c_page_vert_txt;
            background-color: $bg_page_vert_color;
            border-top: 1px solid $bg_page_vert_color;
            border-right: 1px solid $bg_page_vert_color;
            border-bottom: 1px solid $bg_page_vert_color;
            &:first-child {
              border-left-color: $bg_page_vert_color;
            }
          }
          tr:first-child th {
            border-top-color: $bg_page_vert_color;
          }
        }
      }
      .themes-questions-container {
        .themes {
          a {
            &.active {
              &:before {
                background-color: $bg_page_vert_color;
              }
            }
          }
        }
        .questions {
          .question-response {
            &.element-actif {
              > p {
                color: $bg_page_vert_color !important;
              }
            }
          }
          > p {
          }
          .panel-group {
            .panel {
              .panel-heading {
                a {
                  &:hover,
                  &:active:hover {
                    p {
                      color: $bg_page_vert_color;
                    }
                  }
                }
              }
              .panel-collapse {
                .panel-body {
                  border-top-color: $bg_page_vert_color;
                }
              }
              &.contact-panel {
                a {
                  color: $bg_page_vert_color;
                  &:hover,
                  &:active,
                  &:active:hover {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
    .actus {
      .actu {
        .actu-container {
          .rubrique-content {
            .image {
            }
            .filter {
              background-color: rgba($bg_page_vert_color, 0.2);
              p {
                color: $c_page_vert_txt;
              }
            }
          }
          .rubrique-footer {
            .txt-background {
              background-color: $c_page_vert_txt;
              p {
                color: $bg_page_vert_color;
                &:first-child {
                }
              }
              .corner {
                background-color: $bg_page_vert_color;
              }
            }
          }
          &:hover,
          &:active,
          &:active:hover {
            .rubrique-content {
              .image {
              }
              .filter {
                background-color: rgba(darken($bg_page_vert_color, 5%), 0.2);
                p {
                  color: darken($c_page_vert_txt, 5%);
                }
              }
            }
            .rubrique-footer {
              .txt-background {
                background-color: $bg_page_vert_color;
                p {
                  color: $c_page_vert_txt;
                }
                .corner {
                  background-color: $c_page_vert_txt;
                }
              }
            }
          }
        }
      }
    }
  }
  &.bleu {
    .sections-title {
      background-color: $bg_page_bleu_color !important;
      h1 {
        color: $c_page_bleu_txt;
      }
      .angle {
        border-bottom-color: $bg_page_bleu_color !important;
      }
    }
    .link-all {
      background-color: $bg_page_bleu_color_black !important;
      a {
        p {
          color: $c_page_bleu_txt;
        }
        i {
          color: $c_page_bleu_txt;
        }
        &:hover,
        &:active,
        &:active:hover {
          p {
            color: darken($c_page_bleu_txt, 20%);
          }
          i {
            color: darken($c_page_bleu_txt, 20%);
          }
        }
      }
      .angle {
        border-bottom-color: $bg_page_bleu_color_black !important;
      }
    }
    .souligne {
      border-bottom-color: $bg_page_bleu_color !important;
    }
    .form-section {
      .form-title {
        color: $bg_page_bleu_color;
      }
      form {
        .custom-select {
          .select-selected {
            color: $bg_page_bleu_color;
            &:after {
              color: $bg_page_bleu_color;
            }
          }
          .select-items {
            div {
              background-color: $bg_page_bleu_color;
              &:hover,
              &:active,
              &:active:hover {
                color: $bg_page_bleu_color !important;
              }
            }
          }
          .same-as-selected {
            color: $bg_page_bleu_color !important;
          }
        }
        button {
          .background {
            background-color: $bg_page_bleu_color;
          }
          &:hover,
          &:active,
          &:active:hover {
            .background {
              background-color: $c_page_bleu_txt;
              i {
                color: $bg_page_bleu_color;
              }
              p {
                color: $bg_page_bleu_color;
              }
              .corner {
                background-color: $bg_page_bleu_color;
              }
            }
          }
        }
      }
      .epci-choice {
        p {
          color: $bg_page_bleu_color;
        }
      }
    }
    .resultats {
      .nb {
        color: $bg_page_bleu_color;
      }
      table {
        thead {
          th {
            background-color: $bg_page_bleu_color;
            color: $c_page_bleu_txt;
          }
        }
        tbody {
          a {
            i {
              color: $bg_page_bleu_color;
            }
          }
        }
      }
    }
    .content {
      .titre {
        color: $bg_page_bleu_color;
      }
      .txt {
        a {
          color: $bg_page_bleu_color;
        }
        ol {
          li {
            &:before {
              color: $bg_page_bleu_color;
            }
          }
        }
        ul {
          li {
            &:before {
              background-color: $bg_page_bleu_color;
            }
          }
        }
        hr {
          border-top-color: $bg_page_bleu_color;
        }
        table {
          tbody {
            a {
              i {
                color: $bg_page_bleu_color;
              }
            }
          }
          th {
            background-color: $bg_page_bleu_color;
            color: $c_page_bleu_txt;
            background-color: $bg_page_bleu_color;
            border-top: 1px solid $bg_page_bleu_color;
            border-right: 1px solid $bg_page_bleu_color;
            border-bottom: 1px solid $bg_page_bleu_color;
            &:first-child {
              border-left-color: $bg_page_bleu_color;
            }
          }
          tr:first-child th {
            border-top-color: $bg_page_bleu_color;
          }
        }
      }
      .themes-questions-container {
        .themes {
          a {
            &.active {
              &:before {
                background-color: $bg_page_bleu_color;
              }
            }
          }
        }
        .questions {
          .question-response {
            &.element-actif {
              > p {
                color: $bg_page_bleu_color !important;
              }
            }
          }
          > p {
          }
          .panel-group {
            .panel {
              .panel-heading {
                a {
                  &:hover,
                  &:active:hover {
                    p {
                      color: $bg_page_bleu_color;
                    }
                  }
                }
              }
              .panel-collapse {
                .panel-body {
                  border-top-color: $bg_page_bleu_color;
                }
              }
            }
          }
        }
      }
    }
    .actus {
      .actu {
        .actu-container {
          .rubrique-content {
            .image {
            }
            .filter {
              background-color: rgba($bg_page_bleu_color, 0.2);
              p {
                color: $c_page_bleu_txt;
              }
            }
          }
          .rubrique-footer {
            .txt-background {
              background-color: $c_page_bleu_txt;
              p {
                color: $bg_page_bleu_color;
                &:first-child {
                }
              }
              .corner {
                background-color: $bg_page_bleu_color;
              }
            }
          }
          &:hover,
          &:active,
          &:active:hover {
            .rubrique-content {
              .image {
              }
              .filter {
                background-color: rgba(darken($bg_page_bleu_color, 5%), 0.2);
                p {
                  color: darken($c_page_bleu_txt, 5%);
                }
              }
            }
            .rubrique-footer {
              .txt-background {
                background-color: $bg_page_bleu_color;
                p {
                  color: $c_page_bleu_txt;
                }
                .corner {
                  background-color: $c_page_bleu_txt;
                }
              }
            }
          }
        }
      }
    }
  }
  &.rouge {
    .sections-title {
      background-color: $bg_page_rouge_color !important;
      h1 {
        color: $c_page_rouge_txt;
      }
      .angle {
        border-bottom-color: $bg_page_rouge_color !important;
      }
    }
    .link-all {
      background-color: $bg_page_rouge_color_black !important;
      a {
        p {
          color: $c_page_rouge_txt;
        }
        i {
          color: $c_page_rouge_txt;
        }
        &:hover,
        &:active,
        &:active:hover {
          p {
            color: darken($c_page_rouge_txt, 20%);
          }
          i {
            color: darken($c_page_rouge_txt, 20%);
          }
        }
      }
      .angle {
        border-bottom-color: $bg_page_rouge_color_black !important;
      }
    }
    .souligne {
      border-bottom-color: $bg_page_rouge_color !important;
    }
    .form-section {
      .form-title {
        color: $bg_page_rouge_color;
      }
      form {
        .custom-select {
          .select-selected {
            color: $bg_page_rouge_color;
            &:after {
              color: $bg_page_rouge_color;
            }
          }
          .select-items {
            div {
              background-color: $bg_page_rouge_color;
              &:hover,
              &:active,
              &:active:hover {
                color: $bg_page_rouge_color !important;
              }
            }
          }
          .same-as-selected {
            color: $bg_page_rouge_color !important;
          }
        }
        button {
          .background {
            background-color: $bg_page_rouge_color;
          }
          &:hover,
          &:active,
          &:active:hover {
            .background {
              background-color: $c_page_rouge_txt;
              i {
                color: $bg_page_rouge_color;
              }
              p {
                color: $bg_page_rouge_color;
              }
              .corner {
                background-color: $bg_page_rouge_color;
              }
            }
          }
        }
      }
      .epci-choice {
        p {
          color: $bg_page_rouge_color;
        }
      }
    }
    .resultats {
      .nb {
        color: $bg_page_rouge_color;
      }
      table {
        thead {
          th {
            background-color: $bg_page_rouge_color;
            color: $c_page_rouge_txt;
          }
        }
        tbody {
          a {
            i {
              color: $bg_page_rouge_color;
            }
          }
        }
      }
    }
    .content {
      .titre {
        color: $bg_page_rouge_color;
      }
      .txt {
        a {
          color: $bg_page_rouge_color;
        }
        ol {
          li {
            &:before {
              color: $bg_page_rouge_color;
            }
          }
        }
        ul {
          li {
            &:before {
              background-color: $bg_page_rouge_color;
            }
          }
        }
        hr {
          border-top-color: $bg_page_rouge_color;
        }
        table {
          tbody {
            a {
              i {
                color: $bg_page_rouge_color;
              }
            }
          }
          th {
            background-color: $bg_page_rouge_color;
            color: $c_page_rouge_txt;
            background-color: $bg_page_rouge_color;
            border-top: 1px solid $bg_page_rouge_color;
            border-right: 1px solid $bg_page_rouge_color;
            border-bottom: 1px solid $bg_page_rouge_color;
            &:first-child {
              border-left-color: $bg_page_rouge_color;
            }
          }
          tr:first-child th {
            border-top-color: $bg_page_rouge_color;
          }
        }
      }
      .themes-questions-container {
        .themes {
          a {
            &.active {
              &:before {
                background-color: $bg_page_rouge_color;
              }
            }
          }
        }
        .questions {
          .question-response {
            &.element-actif {
              > p {
                color: $bg_page_rouge_color !important;
              }
            }
          }
          > p {
          }
          .panel-group {
            .panel {
              .panel-heading {
                a {
                  &:hover,
                  &:active:hover {
                    p {
                      color: $bg_page_rouge_color;
                    }
                  }
                }
              }
              .panel-collapse {
                .panel-body {
                  border-top-color: $bg_page_rouge_color;
                }
              }
            }
          }
        }
      }
    }
    .actus {
      .actu {
        .actu-container {
          .rubrique-content {
            .image {
            }
            .filter {
              background-color: rgba($bg_page_rouge_color, 0.5);
              p {
                color: $c_page_rouge_txt;
              }
            }
          }
          .rubrique-footer {
            .txt-background {
              background-color: $c_page_rouge_txt;
              p {
                color: $bg_page_rouge_color;
                &:first-child {
                }
              }
              .corner {
                background-color: $bg_page_rouge_color;
              }
            }
          }
          &:hover,
          &:active,
          &:active:hover {
            .rubrique-content {
              .image {
              }
              .filter {
                background-color: rgba(darken($bg_page_rouge_color, 5%), 0.2);
                p {
                  color: darken($c_page_rouge_txt, 5%);
                }
              }
            }
            .rubrique-footer {
              .txt-background {
                background-color: $bg_page_rouge_color;
                p {
                  color: $c_page_rouge_txt;
                }
                .corner {
                  background-color: $c_page_rouge_txt;
                }
              }
            }
          }
        }
      }
    }
  }
  .hachures {
    @include hachures;
    padding-top: 70px;
    margin-bottom: 30px;
  }
  .souligne {
    margin: 0;
  }
  .carte {
    margin-top: 20px;
    position: relative;
    .carte-container {
      position: relative;
      .elus {
        position: absolute;
        top: 0;
        left: 0;
        @include display-flex;
        @include flex-direction;
        cursor: pointer;
        img {
          width: auto;
          margin-bottom: 5px;
        }
        &.marmande {
          top: 28%;
          left: 19%;
        }
        &.villeneuve {
          top: 28%;
          left: 67%;
        }
        &.nerac {
          top: 66%;
          left: 36%;
        }
        &.agen {
          top: 66%;
          left: 67%;
        }
        &.art {
          top: 98%;
          left: 50%;
          transform: translateX(-50%);
          p {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            white-space: nowrap;
            color: $grey;
            font-family: $ubuntu_condensed;
            font-size: 14px;
          }
        }
      }
    }
    img {
      width: 100%;
      &.active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    .hors-departement {
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% / 12);
      height: 100%;
      border-bottom: 200px solid $red;
      border-left: 150px solid transparent;
      p {
        text-align: right;
        font-family: $ubuntu_condensed;
        font-size: 22px;
        color: $white;
        position: absolute;
        bottom: -210px;
        right: 11px;
      }
    }
    .map-points {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      .point {
        position: absolute;
        height: 80px;
        width: 80px;
        animation-name: bounce;
        animation-fill-mode: both;
        animation-duration: 1s;
        .pin {
          width: 100%;
          height: 100%;
          border-radius: 50% 50% 50% 0;
          background: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          transform-origin: center center;
          &:after {
            content: "";
            width: 90%;
            height: 90%;
            background: $red;
            position: absolute;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            box-shadow: inset 1px 3px 5px 3px rgba(0, 0, 0, 0.3);
          }
        }
        .pulse {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          height: 14px;
          width: 14px;
          position: absolute;
          left: 50%;
          bottom: -30%;
          // margin: 11px 0px 0px -12px;
          transform: rotateX(55deg) translateX(-50%);
          z-index: -2;
          &:after {
            content: "";
            border-radius: 50%;
            height: 40px;
            width: 40px;
            position: absolute;
            margin: -13px 0 0 -13px;
            animation: pulsate 1s ease-out;
            animation-iteration-count: infinite;
            opacity: 0;
            box-shadow: 0 0 1px 2px #89849b;
            animation-delay: 1.1s;
          }
        }
        p {
          position: absolute;
          top: 13%;
          left: 50%;
          transform: translateX(-50%);
          font-family: $ubuntu_condensed;
          font-size: 40px;
          color: white;
          margin: 0;
        }
        &.point-pays-duras {
          top: 2%;
          left: 27%;
        }
        &.point-pays-lauzun {
          top: 8%;
          left: 40%;
        }
        &.point-bastide-haut-agenais {
          top: 13%;
          left: 63%;
        }
        &.point-val-garonne {
          top: 21%;
          left: 21%;
        }
        &.point-lot-tolzac {
          top: 28%;
          left: 46%;
        }
        &.point-grand-villeneuvois {
          top: 36%;
          left: 61%;
        }
        &.point-fumel {
          top: 22%;
          left: 85%;
        }
        &.point-coteaux-landes {
          top: 50%;
          left: 12%;
        }
        &.point-coteaux-prayssas {
          top: 47%;
          left: 41%;
        }
        &.point-albret {
          top: 67%;
          left: 30%;
        }
        &.point-agen {
          top: 62%;
          left: 57%;
        }
        &.point-porte-aquitaine {
          top: 48%;
          left: 78%;
        }
        &.point-deux-rives {
          top: 62%;
          left: 75%;
        }
        &.point-hors-departement {
          bottom: 102px;
          right: 8px;
        }
      }
    }
    .legende {
      text-align: center;
      font-style: italic;
      font-family: $ubuntu_condensed;
      font-size: 12px;
      color: darken($light_grey, 30%);
    }
  }
  form {
    input {
      &[type="text"],
      &[type="password"] {
        width: calc(100% * 4 / 7);
        background-color: $bg_page_input;
        border: 0;
        border-radius: 0;
        padding: 25px;
        font-family: $verdana_regular;
        color: $c_page_input;
        margin-bottom: 5px;
        font-size: $fs_page_input;
        box-shadow: none;
        height: auto;
        &::placeholder {
          font-style: italic;
          color: $c_page_input_placeholder;
        }
        &:focus {
          box-shadow: none;
        }
      }
      &[type="checkbox"] {
        color: $c_page_checkbox;
        margin-right: 15px;
      }
      &.full-width {
        width: 100%;
      }
    }
    .custom-select {
      margin-bottom: 5px;
    }
    textarea {
      width: calc(100% * 4 / 7);
      background-color: $bg_page_input;
      border: 0;
      border-radius: 0;
      padding: 25px;
      font-family: $verdana_regular;
      color: $c_page_input;
      margin-bottom: 5px;
      font-size: $fs_page_input;
      box-shadow: none;
      &::placeholder {
        font-style: italic;
        color: $c_page_input_placeholder;
      }
      &:focus {
        box-shadow: none;
      }
    }
    label {
      font-family: $ubuntu_condensed;
      font-size: $fs_page_checkbox;
      font-weight: normal;
      text-transform: uppercase;
      &.all-epci {
        font-weight: bold;
      }
    }
    p {
      font-family: $verdana_regular;
      font-size: $fs_page_input;
      color: $c_page_form_p;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .checkboxs {
      @include display-flex;
      @include align-items-start;
      margin-bottom: 10px;
      input {
        flex-grow: 0;
      }
      label {
        flex-grow: 100%;
        margin: 0;
        font-family: $ubuntu_condensed;
        font-size: $fs_page_checkbox !important;
        line-height: 20px !important;
        margin-left: 15px !important;
        font-weight: normal !important;
      }
    }
    button {
      margin-top: 15px;
      flex: 0 0 auto;
      white-space: normal;
      position: relative;
      border-radius: 0;
      border: 0;
      padding: 0;
      background-color: transparent;
      width: 100%;
      margin-bottom: 15px;
      .background {
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
        padding: 20px;
        height: 100%;
        i {
          margin-right: 15px;
          color: $c_newsletter_button_i;
          font-size: $fs_newsletter_button_i;
        }
        p {
          margin: 0;
          color: $c_newsletter_button_p;
          font-size: $fs_newsletter_button_p;
          font-family: $quicksand_bold;
        }
        .corner {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: $bg_newsletter_button_corner;
          width: 29px;
          height: 29px;
        }
      }
      &:hover,
      &:active,
      &:active:hover {
        .background {
          background-color: $bg_newsletter_button_hover;
          i {
            color: $c_newsletter_button_content_hover;
          }
          p {
            color: $c_newsletter_button_content_hover;
          }
          .corner {
            background-color: $c_newsletter_button_content_hover;
          }
        }
      }
    }
    .alert {
      width: calc(100% * 4 / 7);
      font-family: $ubuntu_condensed;
      font-size: $fs_page_alert;
      border: none;
      border-radius: 0;
      background-color: $bg_page_alert;
      &.alert-danger {
        color: $c_page_alert !important;
        * {
          color: $c_page_alert !important;
        }
      }
      &.alert-info {
        color: $blue;
      }
      button {
        width: auto !important;
        margin: auto !important;
      }
    }
    &.light-grey {
      label {
        font-size: $fs_page_lightgrey_label;
        font-weight: bold;
        text-transform: none;
        p {
          font-family: $ubuntu_condensed;
        }
      }
      .radio {
        display: inline-block;
        label {
          font-size: 14px;
          line-height: 19px;
        }
      }
      input {
        height: auto;
        &[type="text"] {
          width: calc(100% * 9 / 12);
          background-color: $bg_page_lightgrey_input;
          color: $c_page_lightgrey_input;
        }
        &[type="password"] {
          width: calc(100% * 9 / 12);
          background-color: $bg_page_lightgrey_input;
          color: $c_page_lightgrey_input;
        }
        &::placeholder {
          color: rgba(0, 0, 0, 0.62);
        }
      }
      textarea {
        width: calc(100% * 9 / 12);
        background-color: $bg_page_lightgrey_input;
        color: $c_page_lightgrey_input;
      }
      button {
        width: calc(100% * 9 / 12);
        p {
          text-transform: uppercase;
        }
      }
      .alert {
        width: calc(100% * 9 / 12);
      }
    }
    .epci-choice {
      margin: 30px auto;
      p {
        font-family: $ubuntu_condensed;
        font-size: 22px;
        &.ou {
          margin-left: 15%;
          color: darken($light_grey, 30%) !important;
        }
      }
      a {
        p {
          text-decoration: underline;
        }
        &:hover,
        &:active:hover {
          p {
            color: $grey !important;
          }
        }
      }
    }
  }
  .resultats {
    margin-top: 60px;
    padding-top: 50px;
    padding-bottom: 110px;
    background-color: $bg_page_resultats;
    .title-container {
      .sections-title {
        h1 {
          font-weight: bold;
        }
      }
    }
    .nb {
      font-family: $ubuntu_condensed;
      font-size: $fs_page_resultats_nb;
      font-style: italic;
      margin-top: 25px;
      margin-bottom: 35px;
      text-transform: uppercase;
      flex-basis: 100%;
    }
    .dataTables_wrapper {
      @include display-flex;
      @include justify-content-between;
      flex-wrap: wrap;
      table {
        background-color: $bg_page_resultats_table;
        width: 100% !important;
        margin-top: 15px;
        td,
        th {
          border: 1px solid #eee;
        }
        thead {
          th {
            border-bottom-width: 1px;
            text-transform: uppercase;
            font-family: $ubuntu_condensed;
            font-size: $fs_page_resultats_table_th;
            &:after {
              font-size: 18px;
            }
          }
          .sorting {
            &:after {
              opacity: 0.5;
            }
          }
          .sorting_asc,
          .sorting_desc,
          .sorting_asc_disabled,
          .sorting_desc_disabled {
            &:after {
              opacity: 0.8;
            }
          }
        }
        tbody {
          font-family: $ubuntu_condensed;
          font-size: $fs_page_resultats_table_tbody;
          tr:nth-of-type(2n + 1) {
            background-color: $bg_page_resultats_table;
          }
          img {
            width: 100%;
            max-width: 50px;
            margin: auto;
            display: block;
          }
          a {
            display: block;
            text-align: center;
            i {
              font-size: $fs_page_resultats_table_i;
            }
            &:hover,
            &:active,
            &:active:hover {
              cursor: pointer;
              i {
                color: $grey;
              }
            }
          }
        }
        tfoot {
          display: none;
        }
      }
      .html5buttons {
        display: none;
      }
      .dataTables_length {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_resultats_table_label;
        label {
          margin-bottom: 15px;
          select {
            vertical-align: baseline;
            margin: 0 5px;
            display: inline-block;
            width: auto;
          }
        }
      }
      .dataTables_filter {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_resultats_table_label;
        input {
          margin-left: 5px;
          vertical-align: baseline;
          display: inline-block;
          width: auto;
        }
      }
      .dataTables_paginate {
        ul {
          margin: 0;
          .previous,
          .next {
            display: none;
          }
          li {
            a {
              background-color: $transparent;
              border: 0;
              color: $c_page_resultats_pagination_a;
              font-family: $ubuntu_condensed;
              font-size: $fs_page_resultats_pagination_a;
            }
            &.active {
              a {
                color: $c_page_resultats_pagination_a_active;
              }
            }
          }
        }
      }
    }
  }
  .page-fast-access {
    padding-top: 50px;
    padding-bottom: 50px;
    &.grey {
      background-color: $bg_fast_access_grey;
    }
  }
}
@media #{$media_0_1199} {
  section.page {
    .hachures {
      padding-top: 40px;
    }
    .carte {
      .map-points {
        .point {
          &.point-pays-duras {
            top: -3%;
            left: 24%;
          }
          &.point-pays-lauzun {
            top: 3%;
            left: 38%;
          }
          &.point-lot-tolzac {
            top: 23%;
            left: 43%;
          }
          &.point-fumel {
            left: 83%;
          }
          &.point-coteaux-prayssas {
            top: 44%;
          }
          &.point-porte-aquitaine {
            top: 47%;
            left: 75%;
          }
          &.point-deux-rives {
            top: 61%;
            left: 73%;
          }
        }
      }
    }
    form {
      input {
        &[type="text"],
        &[type="password"] {
          padding: 15px;
        }
      }
      .checkboxs {
        label {
          font-size: $fs_page_checkbox_md !important;
        }
      }
      .alert {
        width: calc(100%) !important;
      }
      &.light-grey {
        input {
          &[type="text"] {
            width: 100%;
          }
          &[type="password"] {
            width: 100%;
          }
        }
        textarea {
          width: 100%;
        }
        button {
          width: 100%;
        }
      }
    }
  }
}
@media #{$media_0_991} {
  section.page {
    .carte {
      .hors-departement {
        border-bottom: 156px solid #bb0d0a;
        border-left: 107px solid transparent;
        p {
          font-size: 15px;
          bottom: -168px;
        }
      }
      .map-points {
        .point {
          height: 50px;
          width: 50px;
          p {
            font-size: 26px;
          }
          &.point-pays-duras {
            left: 27%;
          }
          &.point-pays-lauzun {
            top: 6%;
            left: 40%;
          }
          &.point-porte-aquitaine {
            top: 52%;
            left: 76%;
          }
          &.point-deux-rives {
            top: 65%;
            left: 74%;
          }
          &.point-hors-departement {
            bottom: 69px;
          }
        }
      }
    }
    .resultats {
      .dataTables_wrapper {
        table {
          thead {
            th {
              font-size: $fs_page_resultats_table_th_sm;
            }
          }
          tbody {
            font-size: $fs_page_resultats_table_tbody;
          }
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.page {
    .carte {
      display: none;
    }
  }
}
// POPUP ARTISAN
.modal-detail {
  z-index: 9999999999;
  &.jaune {
    .modal-header {
      background-color: $bg_page_jaune_color;
      h4 {
        color: $c_page_jaune_txt;
      }
    }
    strong {
      color: $bg_page_jaune_color;
    }
    .modal-body {
      p {
        font-family: $verdana_regular;
      }
    }
    .modal-footer {
      button {
        background-color: $bg_page_jaune_color;
        color: $c_page_jaune_txt;
        border-color: $bg_page_jaune_color;
        &:hover,
        &:active,
        &:active:hover {
          background-color: $c_page_jaune_txt;
          color: $bg_page_jaune_color;
        }
      }
    }
    .elus-referents {
      border: 1px solid $bg_page_jaune_color;
      .panel-heading {
        background-color: $bg_page_jaune_color;
        border-color: $bg_page_jaune_color;
        h1 {
          color: $c_page_jaune_txt;
        }
      }
      .referent {
        .infos {
          h2 {
            color: $bg_page_jaune_color;
          }
          a {
            p {
              color: $bg_page_jaune_color;
            }
          }
        }
      }
    }
    .agents {
      h3 {
        color: $bg_page_jaune_color;
      }
      .agent {
        & + .agent {
          border-top: 1px solid $bg_page_jaune_color;
        }
      }
    }
  }
  &.vert {
    .modal-header {
      background-color: $bg_page_vert_color;
      h4 {
        color: $c_page_vert_txt;
      }
    }
    strong {
      color: $bg_page_vert_color;
    }
    .modal-footer {
      button {
        background-color: $bg_page_vert_color;
        color: $c_page_vert_txt;
        border-color: $bg_page_vert_color;
        &:hover,
        &:active,
        &:active:hover {
          background-color: $c_page_vert_txt;
          color: $bg_page_vert_color;
        }
      }
    }
    .elus-referents {
      border: 1px solid $bg_page_vert_color;
      .panel-heading {
        background-color: $bg_page_vert_color;
        border-color: $bg_page_vert_color;
        h1 {
          color: $c_page_vert_txt;
        }
      }
      .referent {
        .infos {
          h2 {
            color: $bg_page_vert_color;
          }
          a {
            p {
              color: $bg_page_vert_color;
            }
          }
        }
      }
    }
    .agents {
      h3 {
        color: $bg_page_vert_color;
      }
      .agent {
        & + .agent {
          border-top: 1px solid $bg_page_vert_color;
        }
      }
    }
  }
  &.bleu {
    .modal-header {
      background-color: $bg_page_bleu_color;
      h4 {
        color: $c_page_bleu_txt;
      }
    }
    strong {
      color: $bg_page_bleu_color;
    }
    .modal-footer {
      button {
        background-color: $bg_page_bleu_color;
        color: $c_page_bleu_txt;
        border-color: $bg_page_bleu_color;
        &:hover,
        &:active,
        &:active:hover {
          background-color: $c_page_bleu_txt;
          color: $bg_page_bleu_color;
        }
      }
    }
    .elus-referents {
      border: 1px solid $bg_page_bleu_color;
      .panel-heading {
        background-color: $bg_page_bleu_color;
        border-color: $bg_page_bleu_color;
        h1 {
          color: $c_page_bleu_txt;
        }
      }
      .referent {
        .infos {
          h2 {
            color: $bg_page_bleu_color;
          }
          a {
            p {
              color: $bg_page_bleu_color;
            }
          }
        }
      }
    }
    .agents {
      h3 {
        color: $bg_page_bleu_color;
      }
      .agent {
        & + .agent {
          border-top: 1px solid $bg_page_bleu_color;
        }
      }
    }
  }
  &.rouge {
    .modal-header {
      background-color: $bg_page_rouge_color;
      h4 {
        color: $c_page_rouge_txt;
      }
    }
    strong {
      color: $bg_page_rouge_color;
    }
    .modal-footer {
      button {
        background-color: $bg_page_rouge_color;
        color: $c_page_rouge_txt;
        border-color: $bg_page_rouge_color;
        &:hover,
        &:active,
        &:active:hover {
          background-color: $c_page_rouge_txt;
          color: $bg_page_rouge_color;
        }
      }
    }
    .elus-referents {
      border: 1px solid $bg_page_rouge_color;
      .panel-heading {
        background-color: $bg_page_rouge_color;
        border-color: $bg_page_rouge_color;
        h1 {
          color: $c_page_rouge_txt;
        }
      }
      .referent {
        .infos {
          h2 {
            color: $bg_page_rouge_color;
          }
          a {
            p {
              color: $bg_page_rouge_color;
            }
          }
        }
      }
    }
    .agents {
      h3 {
        color: $bg_page_rouge_color;
      }
      .agent {
        & + .agent {
          border-top: 1px solid $bg_page_rouge_color;
        }
      }
    }
  }
  form {
    &.light-grey {
      input[type="text"],
      textarea {
        width: 100% !important;
        padding: 15px;
      }
      button {
        width: 100% !important;
      }
      label {
        line-height: 0;
        margin-top: 10px;
      }
      .select-selected {
        padding: 15px;
        font-size: 18px;
        &:after {
          padding: 21px 9px;
        }
      }
    }
  }
  .elus-referents {
    .panel-heading {
      h1 {
        font-family: $ubuntu_condensed;
        font-size: $fs_modal_elusreferent_h1;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
      }
    }
    .referent {
      @include display-flex;
      @include justify-content-between;
      @include align-items(center);
      & + .referent {
        border-top: 1px solid $light-grey;
        margin-top: 15px;
      }
      .img-container {
        @include display-flex;
        @include justify-content(center);
        @include align-items(center);
        width: calc(100% * 2 / 12 - 30px);
        img {
          width: auto;
        }
      }
      .infos {
        width: calc(100% * 10 / 12 - 30px);
        font-family: $ubuntu_condensed;
        h2 {
          font-size: $fs_modal_elusreferent_infos_h2;
          font-weight: bold;
        }
        h3 {
          font-size: $fs_modal_elusreferent_infos_h3;
          color: $c_modal_elusreferent_infos_h3;
        }
        a {
          p {
            text-decoration: underline;
          }
          &:hover,
          &:active:hover {
            p {
              color: $c_modal_elusreferent_infos_p_hover;
            }
          }
        }
        p {
          font-size: $fs_modal_elusreferent_infos_p;
        }
      }
    }
  }
  .agents {
    font-family: $ubuntu_condensed;
    margin-top: 30px;
    h3 {
      font-size: $fs_modal_agents_h3;
      color: $c_modal_agents_h3;
      text-transform: uppercase;
      font-weight: bold;
    }
    .agent {
      @include display-flex;
      @include justify-content-between;
      @include align-items(center);
      padding: 15px 0;
      & + .agent {
      }
      .infos {
      }
      button {
        p {
          display: inline-block;
          margin: 0;
          margin-right: 5px;
        }
      }
    }
  }
}

// LISTE ACTUS
section.page.bloc-actualites.liste {
  .container {
    .actus {
      flex-wrap: wrap;
      .actu {
        margin-bottom: 50px;
      }
    }
  }
  &.formation {
    .container {
      .actus {
        .pagination-container {
          .pagination {
            .prev {
              a {
                &::after {
                  content: "Formations précédentes";
                }
              }
            }
            .next {
              a {
                &::after {
                  content: "Formations suivantes";
                }
              }
            }
          }
        }
      }
    }
  }
  &.reunion {
    .container {
      .actus {
        .pagination-container {
          .pagination {
            .prev {
              a {
                &::after {
                  content: "Réunions précédentes";
                }
              }
            }
            .next {
              a {
                &::after {
                  content: "Réunions suivantes";
                }
              }
            }
          }
        }
      }
    }
  }
}
// DETAIL ACTU
section.page.bloc-actualites {
  .content {
    .retour-date-container {
      @include display-flex;
      @include justify-content-between;
      @include align-items(center);
      .date {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_content_date;
        color: $c_page_content_date;
        font-weight: bold;
      }
    }
    .titre {
      font-size: $fs_page_content_titre;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .links {
      @include display-flex;
      @include justify-content-between;
      margin-bottom: 30px;
      a {
        background-color: $bg_actualites_pagination_btn;
        color: $c_actualites_pagination_btn;
        font-family: $ubuntu_condensed;
        font-size: $fs_actualites_pagination_btn;
        font-weight: bold;
        text-transform: uppercase;
        padding: 15px 30px;
        cursor: pointer;
        &:hover,
        &:active:hover {
          background-color: $white;
          color: $grey;
        }
      }
    }
  }
  .main-img-container {
    padding-top: 40px;
    padding-bottom: 40px;
    img {
      max-width: 100%;
    }
  }
  .galerie {
    .container {
      .masonry {
        @include display-flex;
        flex-flow: row wrap;
        margin-left: -15px;
        margin-right: -15px;
        a {
          margin: 0 15px;
          flex: auto;
          max-width: calc(25% - 30px);
          margin-bottom: 30px;
          overflow: hidden;
          img {
            width: 100%;
          }
          &:hover,
          &:active,
          &:active:hover {
            img {
              transform: scale(1.3);
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.page.bloc-actualites {
    .content {
      .retour-date-container {
        .date {
        }
      }
      .titre {
      }
    }
    .main-img-container {
      img {
      }
    }
    .galerie {
      .container {
        .masonry {
          a {
            max-width: calc(50% - 30px);
            img {
            }
            &:hover,
            &:active,
            &:active:hover {
              img {
              }
            }
          }
        }
      }
    }
  }
}
// CONTACT
section.contact {
  form {
    padding-bottom: 30px;
  }
}
// CONNEXION
section.connexion {
  .form-section {
    .container {
      > p {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_connexion_p;
        color: $c_page_connexion_p;
        margin-top: 120px;
      }
    }
  }
  form {
    padding-bottom: 160px;
  }
}
@media #{$media_0_1199} {
  section.connexion {
    .form-section {
      .container {
        > p {
          margin-top: 80px;
        }
      }
    }
    form {
      padding-bottom: 80px;
    }
  }
}
@media #{$media_0_991} {
  section.connexion {
    .form-section {
      .container {
        > p {
          margin-top: 60px;
        }
      }
    }
    form {
      padding-bottom: 60px;
    }
  }
}
@media #{$media_0_767} {
  section.connexion {
    .form-section {
      .container {
        > p {
          margin-top: 40px;
        }
      }
    }
    form {
      padding-bottom: 40px;
    }
  }
}
section.d1 {
  .form-section {
    .container {
      p {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_d1_p;
        color: $c_page_d1_p;
      }
      ul {
        margin-left: -25px;
      }
      .form-title {
        font-family: $ubuntu_condensed;
        font-size: $fs_page_d1_p;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 45px;
      }
      .custom-radio {
        > p {
          display: inline-block;
        }
        input {
          margin-left: 15px;
        }
      }
      .form-mention {
        font-weight: bold;
        margin: 30px 0;
      }
      .custom-checkbox {
        label {
          font-family: $ubuntu_condensed;
          font-size: $fs_page_d1_custom_checkbox_label;
          color: $c_page_d1_custom_checkbox_label;
          font-weight: normal;
          display: inline;
        }
      }
      button {
        margin-bottom: 30px;
        p {
          color: $c_page_d1_btn;
        }
      }
      p {
        margin-top: 0;
      }
    }
  }
}
@media #{$media_0_767} {
  section.d1 {
    .form-section {
      .container {
        p {
          font-size: 16px;
        }
        ul {
        }
        .form-title {
        }
        .custom-radio {
          > p {
            display: block;
          }
          input {
          }
        }
        .form-mention {
        }
        .custom-checkbox {
          label {
          }
        }
        button {
          p {
          }
        }
        p {
        }
      }
    }
  }
}
// 404
section.error404 {
  padding: 170px 50px 190px;
  @include display-flex;
  @include flex-direction;
  @include justify-content-between;
  @include align-items(center);
  text-align: center;
  p {
    font-family: $ubuntu_condensed;
    font-size: $fs_page_404_p;
    color: $c_page_404;
    line-height: 160px;
  }
  h1 {
    font-family: $ubuntu_condensed;
    font-size: $fs_page_404_h1;
    color: $c_page_404;
  }
  h2 {
    font-family: $ubuntu_condensed;
    font-size: $fs_page_404_h2;
    color: $c_page_404;
  }
  a {
    font-family: $ubuntu_condensed;
    font-size: $fs_page_404_a;
    color: $c_page_404_a;
    &:hover {
      text-decoration: underline;
    }
  }
}
@media #{$media_0_1199} {
  section.error404 {
    padding: 100px 50px;
  }
}
@media #{$media_0_991} {
  section.error404 {
    p {
      font-size: 120px;
    }
    h1 {
      font-size: 64px;
    }
  }
}
@media #{$media_0_767} {
  section.error404 {
    p {
      font-size: 40px;
      line-height: 40px;
    }
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 16px;
    }
    a {
      font-size: 14px;
    }
  }
}
// STATISTIQUES
section.statistiques {
  .content {
    margin-top: 90px;
    margin-bottom: 140px;
    .bloc-container {
      @include display-flex;
      @include justify-content-between;
      margin-left: -15px;
      margin-right: -15px;
      flex-wrap: wrap;
      .bloc-faq,
      .bloc-chiffres-cles,
      .carte {
        width: calc(50% - 15px);
      }
      .txt {
        margin-top: 50px;
      }
    }
  }
}
@media #{$media_0_991} {
  section.statistiques {
    .content {
      .bloc-container {
        flex-wrap: wrap;
        .bloc-faq,
        .bloc-chiffres-cles {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
// BLOC FAQ
.bloc-faq {
  background-color: $bg_bloc_faq;
  padding: 40px 30px 30px;
  h4 {
    font-family: $ubuntu_condensed;
    font-size: $fs_bloc_faq_h4;
    color: $c_bloc_faq_h4;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 30px;
  }
  > p {
    font-family: $ubuntu_condensed;
    font-size: $fs_bloc_faq_p;
    color: $c_bloc_faq_p;
    position: relative;
    margin-left: 30px;
    &:before {
      content: ">";
      position: absolute;
      top: 0;
      right: calc(100% + 15px);
    }
  }
  a {
    background-color: $bg_bloc_faq_a;
    padding: 15px;
    display: block;
    @include display-flex;
    @include flex-direction;
    @include align-items(center);
    box-shadow: 5px 5px 6px -6px #89849b;
    i {
      font-size: $fs_bloc_faq_i;
      margin-bottom: 10px;
    }
    p {
      font-family: $ubuntu_condensed;
      font-size: $fs_bloc_faq_a;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0;
      text-align: center;
      &:before {
        display: none;
      }
    }
    &:first-of-type {
      margin-top: 40px;
      margin-bottom: 20px;
      i,
      p {
        color: $c_bloc_faq_a_first;
      }
      &:hover,
      &:active,
      &:active:hover {
        background-color: $c_bloc_faq_a_first;
        i,
        p {
          color: $white;
        }
      }
    }
    &:last-of-type {
      i,
      p {
        color: $c_bloc_faq_a_second;
      }
      &:hover,
      &:active,
      &:active:hover {
        background-color: $c_bloc_faq_a_second;
        i,
        p {
          color: $white;
        }
      }
    }
  }
}
@media #{$media_0_767} {
  .bloc-faq {
    order: 2;
    h4 {
      font-size: 20px;
    }
    > p {
      font-size: 14px;
    }
    a {
      i {
        font-size: 40px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}
.bloc-chiffres-cles {
  background-color: $bg_blog_chiffres_cles;
  padding: 40px 30px 10px;
  @include display-flex;
  @include flex-direction;
  h4 {
    font-family: $ubuntu_condensed;
    font-size: $fs_bloc_chiffrescles_h4;
    color: $c_bloc_chiffrescles_h4;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .stats {
    @include display-flex;
    @include justify-content-between;
    @include align-items(center);
    margin-bottom: 30px;
    flex: 2;
    .legende {
      color: $c_bloc_chiffrescles_legende;
      font-family: $ubuntu_condensed;
      font-size: $fs_bloc_chiffrescles_legende;
      p {
        &:first-of-type {
          font-weight: bold;
        }
        &.item {
          &:before {
            content: "";
            width: 30px;
            height: 14px;
            display: inline-block;
            margin-right: 5px;
          }
          &:nth-of-type(2) {
            &:before {
              background-color: $c_bloc_chiffrescles_legende_first;
            }
          }
          &:nth-of-type(3) {
            &:before {
              background-color: $c_bloc_chiffrescles_legende_second;
            }
          }
          &:nth-of-type(4) {
            &:before {
              background-color: $c_bloc_chiffrescles_legende_third;
            }
          }
          &:nth-of-type(5) {
            &:before {
              background-color: $c_bloc_chiffrescles_legende_fourth;
            }
          }
        }
      }
    }
    .graph {
      width: 60%;
      #chartdiv {
        width: 100%;
        height: 270px;
        .amcharts-chart-div {
          a {
            display: none !important;
          }
        }
        .amcharts-pie-tick {
          display: none;
        }
        .amcharts-pie-label {
          display: none;
        }
      }
    }
  }
  > p {
    text-align: center;
    margin-left: -30px;
    margin-right: -30px;
    padding: 15px;
    font-family: $ubuntu_condensed;
    font-size: $fs_bloc_chiffrescles_p;
    color: $c_bloc_chiffrescles_p;
    font-weight: bold;
    span {
      font-size: $fs_bloc_chiffrescles_p_span;
      font-weight: normal;
    }
    &:nth-child(odd) {
      background-color: $bg_blog_chiffres_cles_dark;
    }
  }
  > a {
    p {
      text-align: center;
      font-family: $ubuntu_condensed;
      font-size: $fs_bloc_chiffrescles_a;
      color: $c_bloc_chiffrescles_a;
      margin: 0;
    }
    &:hover,
    &:active,
    &:active:hover {
      p {
        text-decoration: underline;
      }
    }
  }
}
@media #{$media_0_767} {
  .bloc-chiffres-cles {
    h4 {
      font-size: 20px;
    }
    .stats {
      flex-wrap: wrap;
      .legende {
        order: 2;
      }
      .graph {
        width: 100%;
      }
    }
    > p {
      text-align: center;
      margin-left: -30px;
      margin-right: -30px;
      padding: 15px;
      font-family: $ubuntu_condensed;
      font-size: $fs_bloc_chiffrescles_p;
      color: $c_bloc_chiffrescles_p;
      font-weight: bold;
      span {
        font-size: $fs_bloc_chiffrescles_p_span;
        font-weight: normal;
      }
      &:nth-child(odd) {
        background-color: $bg_blog_chiffres_cles_dark;
      }
    }
    > a {
      p {
        text-align: center;
        font-family: $ubuntu_condensed;
        font-size: $fs_bloc_chiffrescles_a;
        color: $c_bloc_chiffrescles_a;
        margin: 0;
      }
      &:hover,
      &:active,
      &:active:hover {
        p {
          text-decoration: underline;
        }
      }
    }
  }
}
// FAQ LISTE
section.faq {
  .content {
    .retour-btn {
      margin-bottom: 20px;
    }
    .themes-questions-container {
      @include display-flex;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 160px;
      position: relative;
      .theme-container {
        width: calc(100% * 3 / 12);
        margin: 0 30px 0 15px;
        .themes {
          @include display-flex;
          @include flex-direction;
          position: sticky;
          top: 30px;
          a {
            display: block;
            width: 100%;
            background-color: $bg_faq_liste_themes_a;
            margin-bottom: 5px;
            @include display-flex;
            @include align-items(center);
            position: relative;
            p {
              font-family: $ubuntu_condensed;
              font-size: $fs_faq_liste_themes_a;
              font-weight: bold;
              color: $c_faq_liste_themes_a;
              text-transform: uppercase;
              margin: 0;
              padding: 20px;
              margin-left: 10px;
            }
            &:before {
              content: "";
              width: 15px;
              height: 100%;
              display: inline-block;
              margin-right: 10px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
      .questions {
        width: calc(100% * 9 / 12);
        margin: 0 15px 0 30px;
        .question-response {
          > p {
            font-family: $ubuntu_condensed;
            font-size: $fs_faq_liste_question_p;
            font-weight: bold;
            color: $c_faq_liste_question_p;
            text-transform: uppercase;
          }
          .panel-group {
            background-color: $bg_faq_liste_questions_panels;
            padding: 20px 12px;
            .panel {
              border-radius: 0;
              box-shadow: 5px 5px 6px -6px #89849b;
              & + .panel {
                margin-top: 10px;
              }
              p {
                font-family: $ubuntu_condensed;
                font-size: $fs_faq_liste_question_title;
                color: $c_faq_liste_question_title;
                margin: 0;
              }
              .panel-heading {
                a {
                  &:hover,
                  &:active:hover {
                    p {
                      text-decoration: underline;
                    }
                  }
                }
              }
              &.contact-panel {
                a {
                  font-family: $ubuntu_condensed;
                  font-size: $fs_faq_liste_question_title;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.faq {
    .content {
      .retour-btn {
        display: none;
      }
      .themes-questions-container {
        margin: auto;
        .theme-container {
          display: none;
        }
        .questions {
          margin: auto;
          width: 100%;
        }
      }
    }
  }
}
// FOOTER SECONDAIRE
footer.secondaire {
  padding-top: 40px;
  padding-bottom: 40px;
  nav {
    @include display-flex;
    @include justify-content-between;
    @include align-items(center);
    flex-wrap: wrap;
    padding: 0;
    hr {
      flex-basis: 100%;
      width: 100%;
    }
    .links {
      flex-wrap: wrap;
      width: 50%;
      @include align-items-start;
      a {
        width: 50%;
      }
    }
  }
}
@media #{$media_0_767} {
  footer.secondaire {
    nav {
      hr {
      }
      .links {
        width: 100%;
        margin: auto;
        a {
          width: 100%;
        }
      }
      img {
        margin-bottom: 15px;
      }
    }
  }
}
// REPRISE ENTREPRISE DETAIL
section.reprise-entreprise {
  .content {
    padding-bottom: 30px;
    h1 {
      font-family: $ubuntu_condensed;
      font-size: $fs_repriseentreprise_h1;
      background-color: $bg_repriseentreprise_h1;
      color: $c_repriseentreprise_h1;
      width: calc(100% * 5 / 12);
      margin: 0;
      padding: 15px 110px 15px 30px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .detail {
      @include display-flex;
      @include justify-content-between;
      margin-right: -15px;
      margin-left: -15px;
      margin-bottom: 30px;
      .galerie {
        flex: 1;
        margin: 0 15px;
        @include display-flex;
        @include justify-content-between;
        flex-wrap: wrap;
        a {
          width: calc(50% - 15px);
          img {
            max-width: 100%;
            object-fit: cover;
            width: 100%;
          }
          &:first-child {
            flex-basis: 100%;
            margin-bottom: 30px;
            img {
              height: 320px;
            }
          }
        }
      }
      .infos {
        flex: 1;
        margin: 0 15px;
        table {
          background-color: $bg_repriseentreprise_detail_table;
          width: 100% !important;
          margin-bottom: 30px;
          height: 100%;
          * {
            word-break: normal;
          }
          th {
            font-family: $ubuntu_condensed;
            font-size: $fs_repriseentreprise_detail_table_td;
            text-transform: uppercase;
            padding: 10px;
            border-left: 2px solid #fff !important;
            background-color: $bg_repriseentreprise_detail_table_th;
            color: $c_repriseentreprise_detail_table_th;
            border-top: 1px solid $bord_repriseentreprise_detail_table_th;
            border-right: 1px solid $bord_repriseentreprise_detail_table_th;
            border-bottom: 1px solid $bord_repriseentreprise_detail_table_th;
            width: 40%;
          }
          tr th {
            border-top: 2px solid #fff !important;
          }
          td {
            border: 1px solid $bord_repriseentreprise_detail_table_td;
            padding: 10px;
            font-family: $assistant_regular;
          }
          tr:first-child th {
            border-top-color: $bord_repriseentreprise_detail_table_th;
          }
          tr:last-child td {
            text-align: justify;
          }
        }
      }
    }
    .links {
      @include display-flex;
      @include justify-content-between;
      margin-bottom: 30px;
      a {
        background-color: $bg_actualites_pagination_btn;
        color: $c_actualites_pagination_btn;
        font-family: $ubuntu_condensed;
        font-size: $fs_actualites_pagination_btn;
        font-weight: bold;
        text-transform: uppercase;
        padding: 15px 30px;
        cursor: pointer;
        &:hover,
        &:active:hover {
          background-color: $white;
          color: $grey;
        }
      }
    }
    #reprise-contact-form {
      display: none;
    }
  }
}
@media #{$media_0_991} {
  section.reprise-entreprise {
    .content {
      .detail {
        .galerie {
          a {
            width: 100%;
            margin-bottom: 30px;
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.reprise-entreprise {
    .content {
      h1 {
        width: calc(100% * 10 / 12);
        font-size: 20px;
      }
      .detail {
        flex-wrap: wrap;
        .galerie {
          flex-basis: 100%;
          a {
            width: calc(50% - 15px);
            &:last-child {
              display: block;
            }
          }
        }
        .infos {
          table {
            th {
              font-size: 16px;
            }
          }
        }
      }
      .links {
        flex-wrap: wrap;
        a {
          font-size: 20px;
          margin-bottom: 15px;
          width: 100%;
        }
      }
    }
  }
}
.lightboxOverlay {
  z-index: 99999999;
}
.lightbox {
  z-index: 100000000;
}
#modal_apprentissage_msg {
  .alert {
    width: 100% !important;
  }
}
section.logement-detail {
  .flexslider {
    border: none;
    position: relative;
    .slides {
      padding: 0;
      margin: 0;
      height: 100%;
      li {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .custom-navigation {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .control-container {
        padding: 30px;
        cursor: pointer;
        @include transition(all 0.2s ease-in);
        i {
          color: white;
        }
        &:hover {
          i {
            color: $blue;
          }
        }
      }
    }
  }
  .content {
    .detail {
      .galerie {
        display: block;
        border: 0;
      }
    }
  }
}
section.formation-categories {
  .fast-access-btns {
    @include display-flex;
    @include flex-direction-row;
    @include justify-content-between;
    @include align-items(center);
    flex-wrap: wrap;
    padding: 30px 0;
    .fast-access-btn {
      width: calc(25% - 30px);
      margin-bottom: 30px;
      a {
        position: relative;
        display: block;
        position: relative;
        .background {
          @include display-flex;
          flex-wrap: wrap;
          min-height: 136px;
          padding-top: 14px;
          padding-left: 12px;
          padding-right: 20px;
          padding-bottom: 35px;
          background-color: $yellow;
          clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
          .corner {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $white;
            width: 29px;
            height: 29px;
          }
          p {
            font-family: $ubuntu_condensed;
            font-size: $fs_fast_access_btns_a_p;
            color: $white;
            text-transform: uppercase;
            flex-basis: 100%;
          }
          i {
            flex: 1;
            align-self: center;
            color: $white;
            font-size: $fs_fast_access_btns_a_i;
            text-align: center;
          }
          h1 {
            flex: 2;
            align-self: center;
            font-family: $ubuntu_condensed;
            font-size: $fs_fast_access_btns_a_h1;
            color: $white;
            text-transform: uppercase;
            margin: 0;
          }
        }
        .filter {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 29px), calc(100% - 29px) 100%, 0px 100%);
          background-color: $bg_fast_access_btns_a_hover;
        }
        &:hover,
        &:active,
        &:active:hover {
          .background {
            background-color: $white;
            .corner {
              background-color: $yellow;
            }
            p {
              color: $yellow;
            }
            i {
              color: $yellow;
            }
            h1 {
              color: $yellow;
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_1199} {
  section.formation-categories {
    .fast-access-btns {
      .fast-access-btn {
        a {
          .background {
            h1 {
              font-size: $fs_fast_access_btns_a_h1_md;
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_991} {
  section.formation-categories {
    .fast-access-btns {
      .fast-access-btn {
        width: calc(100% / 3 - 30px);
        a {
          .background {
            i {
              display: none;
            }
            h1 {
              flex: auto;
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_767} {
  section.formation-categories {
    .fast-access-btns {
      flex-wrap: wrap;
      .fast-access-btn {
        width: 50%;
        padding: 10px 15px;
        flex: auto;
        a {
          .background {
            min-height: 1px;
            padding: 15px;
            p {
              font-size: $fs_fast_access_btns_a_p_xs;
            }
            h1 {
              font-size: $fs_fast_access_btns_a_h1_xs;
            }
          }
        }
      }
    }
  }
}
@media #{$media_0_500} {
  section.formation-categories {
    .fast-access-btns {
      @include flex-direction;
      .fast-access-btn {
        width: 100%;
        a {
          .background {
            p {
              font-size: $fs_fast_access_btns_a_p_xs;
            }
            h1 {
              font-size: $fs_fast_access_btns_a_h1_xs;
            }
          }
        }
      }
    }
  }
}
.modification-link {
  margin-top: 15px;
  display: block;
  cursor: pointer;
  p {
    font-style: italic;
    color: #777 !important;
    text-decoration: underline;
  }
}
