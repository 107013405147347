/* FONT-FAMILY */

//ASSISTANT
$assistant_bold: "assistantbold",
sans-serif;
$assistant_extrabold: "assistantextrabold",
sans-serif;
$assistant_extralight: "assistantextralight",
sans-serif;
$assistant_light: "assistantlight",
sans-serif;
$assistant_regular: "assistantregular",
sans-serif;
//AVERIA
$averia_bold: "averia_sansbold",
sans-serif;
$averia_bold_italic: "averia_sansbolditalic",
sans-serif;
$averia_italic: "averia_sansitalic",
sans-serif;
$averia_light: "averia_sanslight",
sans-serif;
$averia_light_italic: "averia_sanslightitalic",
sans-serif;
$averia_regular: "averia_sansregular",
sans-serif;
//QUICKSAND
$quicksand_dash: "quicksanddash",
sans-serif;
$quicksand_bold: "quicksandbold",
sans-serif;
$quicksand_bold_italic: "quicksandbold_italic",
sans-serif;
$quicksand_italic: "quicksanditalic",
sans-serif;
$quicksand_light: "quicksandlight",
sans-serif;
$quicksand_light_italic: "quicksandlight_italic",
sans-serif;
$quicksand_regular: "quicksandregular",
sans-serif;
//PT SANS
$pt_caption_regular: "pt_sans_captionregular",
sans-serif;
$pt_caption_bold: "pt_sans_captionbold",
sans-serif;
$pt_narrow_regular: "pt_sans_narrowregular",
sans-serif;
$pt_narrow_bold: "pt_sans_narrowbold",
sans-serif;
$pt_regular: "pt_sansregular",
sans-serif;
$pt_italic: "pt_sansitalic",
sans-serif;
$pt_bold: "pt_sansbold",
sans-serif;
$pt_bold_italic: "pt_sansbold_italic",
sans-serif;
//UBUNTU
$ubuntu_condensed: "ubuntu_condensedregular",
sans-serif;
//VERDANA
$verdana_regular: Verdana, sans-serif;

//ICONS
$picto_fast_access: "icomoon_fast_access";
$picto_blocs: "icomoon_blocs";
$picto_faq: "icomoon_faq";




$base: 0.6rem;



/* FONT-SIZE */

//RETOUR BTN
$fs_retour_btn: 28px;

//CONTENT
$fs_content_h1: 36px;
$fs_content_h2: 28px;
$fs_content_h3: 24px;
$fs_content_h4: 20px;
$fs_content_h5: 18px;
$fs_content_h6: 16px;
$fs_content_p: 16px;

//MAIN-NAV
$fs_header_top_p: 18px;
$fs_header_top_a: 16px;
$fs_header_top_deconnexion: 16px;
$fs_header_bottom_p: 22px;

//SLIDER
$fs_slider_feder_p: 14px;

//FAST ACCESS
$fs_fast_access_title: 35px;
$fs_fast_access_title_md: 26px;
$fs_fast_access_title_sm: 24px;
$fs_fast_access_title_xs: 16px;
$fs_fast_access_btns_a_p: 16px;
$fs_fast_access_btns_a_p_xs: 12px;
$fs_fast_access_btns_a_i: 35px;
$fs_fast_access_btns_a_h1: 23px;
$fs_fast_access_btns_a_h1_md: 18px;
$fs_fast_access_btns_a_h1_xs: 16px;

//BLOCS 
$fs_blocs_h1: 30px;
$fs_blocs_h1_md: 26px;
$fs_blocs_h1_sm: 24px;
$fs_blocs_h1_xs: 16px;
$fs_blocs_link_md: 18px;
$fs_blocs_link_sm: 16px;
$fs_blocs_link_all_p: 20px;
$fs_blocs_title_h2: 24px;
$fs_blocs_title_h2_sm: 18px;
$fs_blocs_title_h2_xs: 18px;
$fs_blocs_title_i: 24px;
$fs_blocs_title_i_sm: 18px;
$fs_blocs_title_i_xs: 18px;
$fs_blocs_content_p: 18px;
$fs_blocs_content_p_sm: 18px;
$fs_blocs_content_calendar: 58px;
$fs_blocs_content_arrow: 16px;
$fs_blocs_content_photo_p: 24px;
$fs_blocs_content_photo_p_sm: 18px;
$fs_blocs_content_a_p: 24px;
$fs_blocs_content_a_p_sm: 18px;
$fs_blocs_footer_a_p: 24px;
$fs_blocs_footer_a_p_sm: 16px;
$fs_blocs_accroche_p: 22px;
$fs_blocs_accroche_p_sm: 18px;

//BLOC ACTUALITES
$fs_blocs_actualites_home_p: 18px;
$fs_blocs_actualites_home_date: 18px;
$fs_actualites_pagination_btn: 28px;

//PARTENAIRES
$fs_partenaires_h1: 32px;
$fs_partenaires_h1_xs: 22px;

//FOOTER HOME
$fs_footer_home_h1: 22px;
$fs_footer_home_h2: 16px;
$fs_footer_home_a: 18px;

//NEWSLETTER
$fs_newsletter_button_i: 22px;
$fs_newsletter_button_p: 16px;

//PAGE
$fs_page_input: 14px;
$fs_page_checkbox: 16px;
$fs_page_checkbox_md: 14px;
$fs_page_resultats_nb: 30px;
$fs_page_resultats_table_th: 20px;
$fs_page_resultats_table_th_sm: 16px;
$fs_page_resultats_table_tbody: 18px;
$fs_page_resultats_table_tbody: 14px;
$fs_page_resultats_table_i: 26px;
$fs_page_resultats_table_label: 16px;
$fs_page_resultats_pagination_a: 25px;
$fs_page_content_date: 25px;
$fs_page_content_titre: 35px;
$fs_page_lightgrey_label: 30px;
$fs_page_alert: 18px;

//CONNEXION
$fs_page_connexion_p: 18px;

//404
$fs_page_404_p: 200px;
$fs_page_404_h1: 93px;
$fs_page_404_h2: 30px;
$fs_page_404_a: 30px;

//BLOC FAQ
$fs_bloc_faq_h4: 26px;
$fs_bloc_faq_p: 21px;
$fs_bloc_faq_i: 83px;
$fs_bloc_faq_i_second: 125px;
$fs_bloc_faq_a: 21px;

//BLOC CHIFFRES CLES
$fs_bloc_chiffrescles_h4: 26px;
$fs_bloc_chiffrescles_legende: 15px;
$fs_bloc_chiffrescles_p: 18px;
$fs_bloc_chiffrescles_p_span: 14px;
$fs_bloc_chiffrescles_a: 14px;

//FAQ LISTE
$fs_faq_liste_themes_a: 16px;
$fs_faq_liste_question_p: 16px;
$fs_faq_liste_question_title: 16px;

//REPRISE ENTREPRISE DETAIL
$fs_repriseentreprise_h1: 32px;
$fs_repriseentreprise_detail_table_td: 20px;

//EXTRAIT D1
$fs_page_d1_p: 22px;
$fs_page_d1_h2: 18px;
$fs_page_d1_custom_checkbox_label: 16px;

//MODAL
$fs_modal_elusreferent_h1: 24px;
$fs_modal_elusreferent_infos_h2: 20px;
$fs_modal_elusreferent_infos_h3: 18px;
$fs_modal_elusreferent_infos_p: 16px;
$fs_modal_agents_h3: 24px;

/* COLORS */

$transparent: transparent;
$white: #fff;
$white_30: rgba(255,255,255,0.3);
$white_50: rgba(255,255,255,0.5);
$white_85: rgba(255,255,255,0.85);
$black: #000;
$black_50: rgba(0, 0, 0, 0.5);
$black_40: rgba(0, 0, 0, 0.4);
$black_30: rgba(0, 0, 0, 0.3);
$black_10: rgba(0, 0, 0, 0.1);
$smooth_black: #252525;
$grey: #535353;
$grey_30: rgba(83, 83, 83, 0.3);
$dark_grey: #484848;
$light_grey: #efefef;
$flashy_red: #c5352d;
$flashy_red_20: rgba(197, 53, 45, 0.2);
$red: #bb0d0a;
$smooth_red: #c5352d;
$smooth_red_70: rgba(197, 53, 45, 0.7);
$dark_red: #980b08;
$black_red: #860a07;
$yellow: #febc11;
$dark_yellow: #e5ac19;
$green: #8dc63f;
$dark_green: #74aa29;
$blue: #0072bc;
$dark_blue: #2b578d;

//SELECTS
$bg_select_styled: $light_grey;
$c_select_styled: $white;
$c_select_styled_after: $red;
$bg_select_styled_after: $transparent;
$bg_select_options: $dark_red;
$c_select_options_li: $white;
$c_select_options_li_hover: $dark_red;
$bg_select_options_li_hover: $white;



//RETOUR BTN
$bg_retour_btn: $grey;
$bord_retour_btn: $grey;
$c_retour_btn: $white;
$bg_retour_btn_hover: $transparent;
$c_retour_btn_hover: $grey;

//CONTENT
$c_content_h1: $grey;
$c_content_h2: $grey;
$c_content_h3: $grey;
$c_content_h4: $grey;
$c_content_h5: $grey;
$c_content_h6: $grey;
$c_content_p: $grey;
$c_content_a: $dark_red;
$bg_content_table: $white;
$bord_content_table: $light_grey;

//MAIN-NAV
$bg_header_top: $flashy_red;
$c_header_top: $white;
$bg_header_top_deconnexion: $transparent;
$bg_header_bottom: $white_85;
$c_header_bottom_p: $grey;
$c_header_bottom_p_hover: $flashy_red;

//SLIDER
$bg_slider_filter: $black_40;
$c_slider_feder_p: $white;
$bg_slider_feder_p: $white_30;

//FAST ACCESS
$bg_fast_access_title: $dark_red;
$c_fast_access_title: $white;
$bg_fast_access_btns: $red;
$bg_fast_access_btns_last: $black_red;
$c_fast_access_btns_a: $white;
$c_fast_access_btns_a_p: $grey;
$c_fast_access_btns_a_h1: $yellow;
$c_fast_access_btns_a_first: $green;
$c_fast_access_btns_a_second: $blue;
$bg_fast_access_btns_a_corner: $yellow;
$bg_fast_access_btns_a_corner_first: $green;
$bg_fast_access_btns_a_corner_second: $blue;
$bg_fast_access_btns_a_corner_hover: $red;
$bg_fast_access_btns_a_corner_hover: $white;
$c_fast_access_btns_a_content_hover: $white;
$bg_fast_access_btns_a_hover_first: $green;
$bg_fast_access_btns_a_hover_second: $blue;
$bg_fast_access_btns_a_hover: $yellow;

//BLOCS 
$bg_blocs_content_photo_filter: $black_50;
$bg_blocs_content_photo_txt: $white;

//DEVENIR ARTISAN
$bg_section_devenir_artisan: $light_grey;
$bg_bloc_devenir_artisan_title: $green;
$bord_bloc_devenir_artisan_title: $green;
$c_bloc_devenir_artisan_title: $white;
$bord_bloc_devenir_artisan_souligne: $green;
$bg_bloc_reunion_information_bloc: $green;
$c_bloc_reunion_information_rubrique_titre: $white;
$bg_blocs_reunion_information_rubrique_content: $black_30;
$c_bloc_reunion_information_rubrique_content: $white;
$bg_bloc_reunion_information_rubrique_content_a: $white;
$c_bloc_reunion_information_rubrique_content_a: $green;
$c_bloc_reunion_information_rubrique_footer: $white;
$bg_bloc_reunion_information_corner: $white;
$bg_bloc_reprise_entreprise_bloc: $white;
$c_bloc_reprise_entreprise_rubrique_titre: $grey;
$c_bloc_reprise_entreprise_rubrique_titre_i: $grey;
$c_bloc_reprise_entreprise_rubrique_content: $grey;
$bg_bloc_reprise_entreprise_rubrique_content_a: $grey;
$c_bloc_reprise_entreprise_rubrique_content_a: $white;
$c_bloc_reprise_entreprise_rubrique_footer: $grey;
$c_bloc_reprise_entreprise_rubrique_footer_arrow: $green;
$bg_bloc_reprise_entreprise_corner: $green;
$bg_bloc_reprise_entreprise_bloc_hover: $green;
$c_bloc_reprise_entreprise_bloc_content_hover: $white;

//DEVENIR ARTISAN
$bg_bloc_devenir_apprenti_title: $white ;
$bord_bloc_devenir_apprenti_title: $white;
$c_bloc_devenir_apprenti_title: $blue;
$bord_bloc_devenir_apprenti_souligne: $white;
$bg_bloc_apprentissage_bloc: $white;
$c_bloc_apprentissage_rubrique_titre: $grey;
$c_bloc_apprentissage_rubrique_content: $grey;
$bg_bloc_apprentissage_rubrique_content_a: $grey;
$c_bloc_apprentissage_rubrique_content_a: $white;
$c_bloc_apprentissage_rubrique_footer: $grey;
$c_bloc_apprentissage_rubrique_footer_arrow: $blue;
$bg_bloc_apprentissage_corner: $blue;
$bg_bloc_apprentissage_bloc_hover: $blue;
$c_bloc_apprentissage_bloc_content_hover: $white;
$bg_bloc_hebergement_bloc: $white;
$c_bloc_hebergement_rubrique_titre: $grey;
$c_bloc_hebergement_rubrique_titre_i: $grey;
$c_bloc_hebergement_rubrique_content: $grey;
$bg_bloc_hebergement_rubrique_content_a: $grey;
$c_bloc_hebergement_rubrique_content_a: $white;
$c_bloc_hebergement_rubrique_footer: $grey;
$c_bloc_hebergement_rubrique_footer_arrow: $blue;
$bg_bloc_hebergement_corner: $blue;
$bord_bloc_accroche: $white;
$bg_bloc_hebergement_rubrique_hover: $blue;
$c_bloc_hebergement_rubrique_content_hover: $white;

//DEVELOPPER SON ENTREPRISE ARTISANALE
$bg_developper_artisanale: $white;
$bg_bloc_developper_artisanale_title: $yellow ;
$bord_bloc_developper_artisanale_title: $yellow;
$c_bloc_developper_artisanale_title: $white;
$bord_bloc_developper_artisanale_souligne: $yellow;
$bg_bloc_formalite_bloc: $yellow;
$c_bloc_formalite_rubrique_titre: $white;
$c_bloc_formalite_rubrique_titre_i: $white;
$c_bloc_formalite_rubrique_content: $white;
$bg_bloc_formalite_rubrique_content_a: $white;
$c_bloc_formalite_rubrique_content_a: $yellow;
$c_bloc_formalite_rubrique_footer: $white;
$c_bloc_formalite_rubrique_footer_arrow: $white;
$bg_bloc_formalite_corner: $white;
$bg_bloc_formalite_bloc_hover: $white;
$c_bloc_formalite_bloc_content_hover: $grey;
$c_bloc_formalite_bloc_content_color_hover: $yellow;
$bg_bloc_mode_emploi_bloc: $yellow;
$c_bloc_mode_emploi_rubrique_titre: $white;
$c_bloc_mode_emploi_rubrique_titre_i: $white;
$c_bloc_mode_emploi_rubrique_content: $white;
$bg_bloc_mode_emploi_rubrique_content_a: $white;
$c_bloc_mode_emploi_rubrique_content_a: $yellow;
$c_bloc_mode_emploi_rubrique_footer: $white;
$c_bloc_mode_emploi_rubrique_footer_arrow: $white;
$bg_bloc_mode_emploi_corner: $white;
$bg_bloc_mode_emploi_bloc_hover: $white;
$c_bloc_mode_emploi_bloc_content_hover: $grey;
$c_bloc_mode_emploi_bloc_content_color_hover: $yellow;
$bg_bloc_catalogue_formation_bloc: $yellow;
$c_bloc_catalogue_formation_rubrique_titre: $white;
$c_bloc_catalogue_formation_rubrique_titre_i: $white;
$c_bloc_catalogue_formation_rubrique_content: $white;
$bg_bloc_catalogue_formation_rubrique_content_a: $white;
$c_bloc_catalogue_formation_rubrique_content_a: $yellow;
$c_bloc_catalogue_formation_rubrique_footer: $white;
$c_bloc_catalogue_formation_rubrique_footer_arrow: $white;
$bg_bloc_catalogue_formation_corner: $white;
$bg_bloc_catalogue_formation_bloc_hover:$white;
$c_bloc_catalogue_formation_bloc_content_hover:$grey;
$c_bloc_catalogue_formation_bloc_content_color_hover:$yellow;

//BLOC ACTUALITES
$bg_actualites_home: $white;
$bg_bloc_actualites_home_title: $red ;
$bord_bloc_actualites_home_title: $red;
$c_bloc_actualites_home_title: $white;
$bord_bloc_actualites_home_souligne: $red;
$bg_blocs_actualites_home_bloc: $white;
$bg_blocs_actualites_home_content_photo_filter: $smooth_red_70;
$c_blocs_actualites_home_date: $white;
$c_blocs_actualites_home_p: $smooth_red;
$bg_blocs_actualites_home_corner: $smooth_red;
$bg_blocs_actualites_home_link_all: $dark_red;
$bord_blocs_actualites_home_link_all: $dark_red;
$c_blocs_actualites_home_link_all_i: $white;
$c_blocs_actualites_home_link_all_p: $white;
$bg_blocs_actualites_home_hr: $flashy_red_20;
$bg_blocs_actualites_home_bloc_hover: $smooth_red;
$c_blocs_actualites_home_bloc_content_hover: $white;
$bg_actualites_pagination_btn: $grey;
$c_actualites_pagination_btn: $white;

//PARTENAIRES
$bord_partenaires_souligne: $grey_30;
$bg_partenaires_h1: $white;
$c_partenaires_h1: $grey;
$shadow_partenaires: 1px 1px 6px $black_40;

//FRISE
$bg_frise: $red;
$bg_frise_vert: $green;
$bg_frise_bleu: $blue;
$bg_frise_jaune: $yellow;

//FOOTER HOME
$bg_footer_home: $dark_grey;
$c_footer_home_h1: $white;
$c_footer_home_h2: $white;
$bg_footer_home_hr: rgba(20, 20, 20, 0.66);
$bord_footer_home_hr: rgba(135, 135, 135, 0.6);
$c_footer_home_a: $white;
$c_footer_home_a_hover: $flashy_red;

//NEWSLETTER
$bg_newsletter_input: $smooth_black;
$c_newsletter_input: $white;
$c_newsletter_input_placeholder: $white_50;
$bg_newsletter_button: $red;
$c_newsletter_button_p: $white;
$c_newsletter_button_i: $white;
$bg_newsletter_button_corner: $white;
$bg_newsletter_button_hover: $white;
$c_newsletter_button_content_hover: $red;

//PAGES
$bg_page_jaune_color: $yellow;
$bg_page_jaune_color_black: $dark_yellow;
$c_page_jaune_txt: $white;
$bg_page_vert_color: $green;
$bg_page_vert_color_black: $dark_green;
$c_page_vert_txt: $white;
$bg_page_bleu_color: $blue;
$bg_page_bleu_color_black: $dark_blue;
$c_page_bleu_txt: $white;
$bg_page_rouge_color: $red;
$bg_page_rouge_color_black: $dark_red;
$c_page_rouge_txt: $white;
$bg_page_input: $smooth_black;
$bg_page_lightgrey_input: $light_grey;
$c_page_input: $white;
$c_page_lightgrey_input: $grey;
$c_page_input_placeholder: $white_50;
$c_page_form_p: $grey;
$c_page_checkbox: $grey;
$bg_page_resultats: $light_grey;
$bg_page_resultats_table: $white;
$c_page_resultats_pagination_a: $grey_30;
$c_page_resultats_pagination_a_active: $grey;
$bg_fast_access_grey: $light_grey;
$c_page_content_date: $grey;
$c_page_content_titre: $smooth_red;
$bg_page_alert: $light_grey;
$c_page_alert: $red;

//CONNEXION
$c_page_connexion_p: $grey;

//404
$c_page_404: $grey;
$c_page_404_a: $red;

//BLOC FAQ
$bg_bloc_faq: $light_grey;
$c_bloc_faq_h4: $dark_red;
$c_bloc_faq_p: $grey;
$bg_bloc_faq_a: $white;
$c_bloc_faq_a_first: $green;
$c_bloc_faq_a_second: $yellow;

//BLOC CHIFFRES CLES
$bg_blog_chiffres_cles: $light_grey;
$bg_blog_chiffres_cles_dark: darken($light_grey,5%);
$c_bloc_chiffrescles_h4: $dark_blue;
$c_bloc_chiffrescles_legende: $dark_blue;
$c_bloc_chiffrescles_legende_first: $blue;
$c_bloc_chiffrescles_legende_second: $red;
$c_bloc_chiffrescles_legende_third: $yellow;
$c_bloc_chiffrescles_legende_fourth: $green;
$c_bloc_chiffrescles_p: $dark_blue;
$c_bloc_chiffrescles_a: $dark_blue;

//FAQ LISTE
$bg_faq_liste_themes_a: $light_grey;
$c_faq_liste_themes_a: $grey;
$bg_faq_liste_questions_panels: $light_grey;
$c_faq_liste_question_p: $grey;
$c_faq_liste_question_title: $grey;

//REPRISE ENTREPRISE DETAIL
$bg_repriseentreprise_h1: $grey;
$c_repriseentreprise_h1: $white;
$bg_repriseentreprise_detail_table: $white;
$bg_repriseentreprise_detail_table_th: $grey;
$bord_repriseentreprise_detail_table_th: $grey;
$c_repriseentreprise_detail_table_th: $white;
$bord_repriseentreprise_detail_table_td: $light_grey;

//EXTRAIT D1
$c_page_d1_p: $grey;
$c_page_d1_custom_checkbox_label: $grey;
$c_page_d1_btn: $white;

//MODAL
$c_modal_elusreferent_infos_h3: $grey;
$c_modal_elusreferent_infos_p_hover: $grey;
$c_modal_agents_h3: $grey;

/* BACKGROUND-IMAGES */

//GLOBAL
$bgImg_shadow1: url(../images/shadow1.png);

//MAIN-NAV
$bgImg_heaser_top_separator: url(../images/header-separator.jpg);

//FAST ACCESS
$bgImg_fast_access_shadow: url(../images/fast-access-shadow.png);

//DEVENIR ARTISAN
$bgImg_blocs_content_photo: url(../images/photo-reprise-1.jpg);

//DEVENIR APPRENTI
$bgImg_section_devenir_apprenti: url(../images/violon-bleu.jpg);
$bgImg_blocs_apprentissage_content_photo: url(../images/photo-apprentissage-1.jpg);
$bgImg_blocs_hebergement_content_photo: url(../images/photo-logement-1.jpg);

//DEVELOPPER SON ENTREPRISE ARTISANALE
$bgImg_blocs_formalite_content_photo: url(../images/photo-logement-1.jpg);
$bgImg_blocs_mode_emploi_content_photo: url(../images/photo-mode-demploi-1.jpg);
$bgImg_blocs_catalogue_formation_content_photo: url(../images/photo-cata-formation-1.jpg);

//HEADER PAGE INTERNES
$bgImg_header_trouver_artisan: url(../images/header-trouver-artisan.jpg);
$bgImg_header_actus: url(../images/header-actu.jpg);
$bgImg_header_general: url(../images/header-general.jpg);
$bgImg_header_devenir_artisan: url(../images/header-devenir-artisan.jpg);
$bgImg_header_recrute: url(../images/header-recrute.jpg);
$bgImg_header_devenir_apprenti: url(../images/header-devenir-apprenti.jpg);
$bgImg_header_developper_entreprise: url(../images/header-developper-entreprise.jpg);


/* MEDIA-QUERIES */

$media_1200: "screen and (min-width:1200px)";
$media_768: "screen and (min-width:768px)";
$media_992_1199: "screen and (min-width:992px) and (max-width:1199px)";
$media_768_991: "screen and (min-width:768px) and (max-width:991px)";
$media_480_767: "screen and (min-width:480px) and (max-width:767px)";
$media_320_479: "screen and (min-width:320px) and (max-width:479px)";
$media_0_1199: "screen and (max-width:1199px)";
$media_0_991: "screen and (max-width:991px)";
$media_0_767: "screen and (max-width:767px)";
$media_0_500: "screen and (max-width:500px)";
$media_0_359: "screen and (max-width:359px)";
$media_0_319: "screen and (max-width:319px)";

/* MIXINS */

@mixin transition($args) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin transform($arg...) {
	-webkit-transform: $arg;
	-ms-transform: $arg;
	transform: $arg;
}

/* pour centrer verticalement */

@mixin drop-shadow($arg...) {
	-webkit-filter: drop-shadow($arg);
	filter: drop-shadow($arg);
}

@mixin display-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin display-flex-important {
	display: -webkit-box !important;
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

@mixin flex-direction {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

@mixin flex-direction-row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

@mixin flex-direction-reverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

@mixin justify-content-between {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@mixin justify-content($justification) {
	-webkit-box-pack: $justification;
	-webkit-justify-content: $justification;
	-ms-flex-pack: $justification;
	justify-content: $justification;
}

@mixin justify-content-start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

@mixin justify-content-end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

@mixin justify-content-around {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

@mixin align-items($align) {
	-webkit-box-align: $align;
	-webkit-align-items: $align;
	-ms-flex-align: $align;
	align-items: $align;
}

@mixin align-items-start {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

@mixin align-items-end {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

@mixin align-items-start {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

@mixin degrade_shadow {
	background: -webkit-radial-gradient(5% -60%, circle farthest-corner, #3f3f3f 80%, rgba(0, 0, 0, 0) 100%);
	background: -o-radial-gradient(5% -60%, circle farthest-corner, #3f3f3f 80%, rgba(0, 0, 0, 0) 100%);
	background: radial-gradient(circle farthest-corner at 5% -60%, #3f3f3f 80%, rgba(0, 0, 0, 0) 100%);
}

@mixin hachures {
	background: -webkit-repeating-linear-gradient( 315deg, #dbdbdb, #dbdbdb 1px, #fff 1px, #fff 5px );
	background: -o-repeating-linear-gradient( 315deg, #dbdbdb, #dbdbdb 1px, #fff 1px, #fff 5px );
	background: repeating-linear-gradient( 135deg, #dbdbdb, #dbdbdb 1px, #fff 1px, #fff 5px );
}


@keyframes pulsate {
	0%{
		transform: scale(0.1, 0.1);
		opacity: 0.0;
	}
	50%{
		opacity: 1.0;
	}
	100%{
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

@keyframes bounce {
	0%{
		transform: translateY(-2000px);
		opacity: 0.0;
	}
	60%{
		opacity: 1;
		transform: translateY(30px);
	}
	80%{
		transform: translateY(-10px);
	}
	100%{
		transform: translateY(0);
	}
}