//ASSISTANT

//ASSISTANT SEMIBOLD

@font-face {
    font-family: 'assistantsemibold';
    src: url('../fonts/assistant/assistant-semibold-webfont.eot');
    src: url('../fonts/assistant/assistant-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/assistant/assistant-semibold-webfont.woff2') format('woff2'),
         url('../fonts/assistant/assistant-semibold-webfont.woff') format('woff'),
         url('../fonts/assistant/assistant-semibold-webfont.ttf') format('truetype'),
         url('../fonts/assistant/assistant-semibold-webfont.svg#assistantsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//ASSISTANT BOLD

@font-face {
    font-family: 'assistantbold';
    src: url('../fonts/assistant/assistant-bold-webfont.eot');
    src: url('../fonts/assistant/assistant-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/assistant/assistant-bold-webfont.woff2') format('woff2'),
         url('../fonts/assistant/assistant-bold-webfont.woff') format('woff'),
         url('../fonts/assistant/assistant-bold-webfont.ttf') format('truetype'),
         url('../fonts/assistant/assistant-bold-webfont.svg#assistantbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//ASSISTANT EXTRABOLD

@font-face {
    font-family: 'assistantextrabold';
    src: url('../fonts/assistant/assistant-extrabold-webfont.eot');
    src: url('../fonts/assistant/assistant-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/assistant/assistant-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/assistant/assistant-extrabold-webfont.woff') format('woff'),
         url('../fonts/assistant/assistant-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/assistant/assistant-extrabold-webfont.svg#assistantextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//ASSISTANT EXTRALIGHT

@font-face {
    font-family: 'assistantextralight';
    src: url('../fonts/assistant/assistant-extralight-webfont.eot');
    src: url('../fonts/assistant/assistant-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/assistant/assistant-extralight-webfont.woff2') format('woff2'),
         url('../fonts/assistant/assistant-extralight-webfont.woff') format('woff'),
         url('../fonts/assistant/assistant-extralight-webfont.ttf') format('truetype'),
         url('../fonts/assistant/assistant-extralight-webfont.svg#assistantextralight') format('svg');
    font-weight: normal;
    font-style: normal;
}

//ASSISTANT LIGHT

@font-face {
    font-family: 'assistantlight';
    src: url('../fonts/assistant/assistant-light-webfont.eot');
    src: url('../fonts/assistant/assistant-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/assistant/assistant-light-webfont.woff2') format('woff2'),
         url('../fonts/assistant/assistant-light-webfont.woff') format('woff'),
         url('../fonts/assistant/assistant-light-webfont.ttf') format('truetype'),
         url('../fonts/assistant/assistant-light-webfont.svg#assistantlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

//ASSISTANT REGULAR

@font-face {
    font-family: 'assistantregular';
    src: url('../fonts/assistant/assistant-regular-webfont.eot');
    src: url('../fonts/assistant/assistant-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/assistant/assistant-regular-webfont.woff2') format('woff2'),
         url('../fonts/assistant/assistant-regular-webfont.woff') format('woff'),
         url('../fonts/assistant/assistant-regular-webfont.ttf') format('truetype'),
         url('../fonts/assistant/assistant-regular-webfont.svg#assistantregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//AVERIA

//AVERIA BOLD

@font-face {
    font-family: 'averia_sansbold';
    src: url('../fonts/averia/averiasans-bold-webfont.eot');
    src: url('../fonts/averia/averiasans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/averia/averiasans-bold-webfont.woff2') format('woff2'),
         url('../fonts/averia/averiasans-bold-webfont.woff') format('woff'),
         url('../fonts/averia/averiasans-bold-webfont.ttf') format('truetype'),
         url('../fonts/averia/averiasans-bold-webfont.svg#averia_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//AVERIA BOLD ITALID

@font-face {
    font-family: 'averia_sansbolditalic';
    src: url('../fonts/averia/averiasans-bolditalic-webfont.eot');
    src: url('../fonts/averia/averiasans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/averia/averiasans-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/averia/averiasans-bolditalic-webfont.woff') format('woff'),
         url('../fonts/averia/averiasans-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/averia/averiasans-bolditalic-webfont.svg#averia_sansbolditalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//AVERIA ITALIC

@font-face {
    font-family: 'averia_sansitalic';
    src: url('../fonts/averia/averiasans-italic-webfont.eot');
    src: url('../fonts/averia/averiasans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/averia/averiasans-italic-webfont.woff2') format('woff2'),
         url('../fonts/averia/averiasans-italic-webfont.woff') format('woff'),
         url('../fonts/averia/averiasans-italic-webfont.ttf') format('truetype'),
         url('../fonts/averia/averiasans-italic-webfont.svg#averia_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//AVERIA LIGHT

@font-face {
    font-family: 'averia_sanslight';
    src: url('../fonts/averia/averiasans-light-webfont.eot');
    src: url('../fonts/averia/averiasans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/averia/averiasans-light-webfont.woff2') format('woff2'),
         url('../fonts/averia/averiasans-light-webfont.woff') format('woff'),
         url('../fonts/averia/averiasans-light-webfont.ttf') format('truetype'),
         url('../fonts/averia/averiasans-light-webfont.svg#averia_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

//AVERIA LIGHT ITALIC

@font-face {
    font-family: 'averia_sanslightitalic';
    src: url('../fonts/averia/averiasans-lightitalic-webfont.eot');
    src: url('../fonts/averia/averiasans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/averia/averiasans-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/averia/averiasans-lightitalic-webfont.woff') format('woff'),
         url('../fonts/averia/averiasans-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/averia/averiasans-lightitalic-webfont.svg#averia_sanslightitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//AVERIA REGULAR

@font-face {
    font-family: 'averia_sansregular';
    src: url('../fonts/averia/averiasans-regular-webfont.eot');
    src: url('../fonts/averia/averiasans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/averia/averiasans-regular-webfont.woff2') format('woff2'),
         url('../fonts/averia/averiasans-regular-webfont.woff') format('woff'),
         url('../fonts/averia/averiasans-regular-webfont.ttf') format('truetype'),
         url('../fonts/averia/averiasans-regular-webfont.svg#averia_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND

//QUICKSAND DASH

@font-face {
    font-family: 'quicksanddash';
    src: url('../fonts/quicksand/quicksand_dash-webfont.eot');
    src: url('../fonts/quicksand/quicksand_dash-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand_dash-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand_dash-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand_dash-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand_dash-webfont.svg#quicksanddash') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND BOLD

@font-face {
    font-family: 'quicksandbold';
    src: url('../fonts/quicksand/quicksand-bold-webfont.eot');
    src: url('../fonts/quicksand/quicksand-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-bold-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-bold-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-bold-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-bold-webfont.svg#quicksandbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND BOLD ITALIC

@font-face {
    font-family: 'quicksandbold_italic';
    src: url('../fonts/quicksand/quicksand-bolditalic-webfont.eot');
    src: url('../fonts/quicksand/quicksand-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-bolditalic-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-bolditalic-webfont.svg#quicksandbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND ITALIC

@font-face {
    font-family: 'quicksanditalic';
    src: url('../fonts/quicksand/quicksand-italic-webfont.eot');
    src: url('../fonts/quicksand/quicksand-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-italic-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-italic-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-italic-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-italic-webfont.svg#quicksanditalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND LIGHT

@font-face {
    font-family: 'quicksandlight';
    src: url('../fonts/quicksand/quicksand-light-webfont.eot');
    src: url('../fonts/quicksand/quicksand-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-light-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-light-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-light-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-light-webfont.svg#quicksandlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND LIGHT ITALIC

@font-face {
    font-family: 'quicksandlight_italic';
    src: url('../fonts/quicksand/quicksand-lightitalic-webfont.eot');
    src: url('../fonts/quicksand/quicksand-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-lightitalic-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-lightitalic-webfont.svg#quicksandlight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//QUICKSAND REGULAR

@font-face {
    font-family: 'quicksandregular';
    src: url('../fonts/quicksand/quicksand-regular-webfont.eot');
    src: url('../fonts/quicksand/quicksand-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-regular-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-regular-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-regular-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-regular-webfont.svg#quicksandregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS

//PT SANS CAPTION REGULAR

@font-face {
    font-family: 'pt_sans_captionregular';
    src: url('../fonts/pt_sans/ptc55f-webfont.eot');
    src: url('../fonts/pt_sans/ptc55f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/ptc55f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/ptc55f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/ptc55f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/ptc55f-webfont.svg#pt_sans_captionregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS CAPTION REGULAR

@font-face {
    font-family: 'pt_sans_captionbold';
    src: url('../fonts/pt_sans/ptc75f-webfont.eot');
    src: url('../fonts/pt_sans/ptc75f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/ptc75f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/ptc75f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/ptc75f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/ptc75f-webfont.svg#pt_sans_captionbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS NARROW REGULAR

@font-face {
    font-family: 'pt_sans_narrowregular';
    src: url('../fonts/pt_sans/ptn57f-webfont.eot');
    src: url('../fonts/pt_sans/ptn57f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/ptn57f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/ptn57f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/ptn57f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/ptn57f-webfont.svg#pt_sans_narrowregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS NARROW BOLD

@font-face {
    font-family: 'pt_sans_narrowbold';
    src: url('../fonts/pt_sans/ptn77f-webfont.eot');
    src: url('../fonts/pt_sans/ptn77f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/ptn77f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/ptn77f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/ptn77f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/ptn77f-webfont.svg#pt_sans_narrowbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS REGULAR

@font-face {
    font-family: 'pt_sansregular';
    src: url('../fonts/pt_sans/pts55f-webfont.eot');
    src: url('../fonts/pt_sans/pts55f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/pts55f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/pts55f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/pts55f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/pts55f-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS ITALIC

@font-face {
    font-family: 'pt_sansitalic';
    src: url('../fonts/pt_sans/pts56f-webfont.eot');
    src: url('../fonts/pt_sans/pts56f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/pts56f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/pts56f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/pts56f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/pts56f-webfont.svg#pt_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS BOLD

@font-face {
    font-family: 'pt_sansbold';
    src: url('../fonts/pt_sans/pts75f-webfont.eot');
    src: url('../fonts/pt_sans/pts75f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/pts75f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/pts75f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/pts75f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/pts75f-webfont.svg#pt_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//PT SANS BOLD ITALIC

@font-face {
    font-family: 'pt_sansbold_italic';
    src: url('../fonts/pt_sans/pts76f-webfont.eot');
    src: url('../fonts/pt_sans/pts76f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans/pts76f-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans/pts76f-webfont.woff') format('woff'),
         url('../fonts/pt_sans/pts76f-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans/pts76f-webfont.svg#pt_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//UBUNTU

//UBUNTU CONDENSED

@font-face {
    font-family: 'ubuntu_condensedregular';
    src: url('../fonts/ubuntu/ubuntu.condensed-webfont.eot');
    src: url('../fonts/ubuntu/ubuntu.condensed-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu/ubuntu.condensed-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu/ubuntu.condensed-webfont.woff') format('woff'),
         url('../fonts/ubuntu/ubuntu.condensed-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu/ubuntu.condensed-webfont.svg#ubuntu_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

//ICOMOON FAST ACCESS

@font-face {
  font-family: 'icomoon_fast_access';
  src:  url('../fonts/icomoon_fast_access/icomoon.eot?momjkm');
  src:  url('../fonts/icomoon_fast_access/icomoon.eot?momjkm#iefix') format('embedded-opentype'),
    url('../fonts/icomoon_fast_access/icomoon.ttf?momjkm') format('truetype'),
    url('../fonts/icomoon_fast_access/icomoon.woff?momjkm') format('woff'),
    url('../fonts/icomoon_fast_access/icomoon.svg?momjkm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

//ICOMOON BLOCS

@font-face {
  font-family: 'icomoon_blocs';
  src:  url('../fonts/icomoon_blocs/icomoon.eot?tx1ev2');
  src:  url('../fonts/icomoon_blocs/icomoon.eot?tx1ev2#iefix') format('embedded-opentype'),
    url('../fonts/icomoon_blocs/icomoon.ttf?tx1ev2') format('truetype'),
    url('../fonts/icomoon_blocs/icomoon.woff?tx1ev2') format('woff'),
    url('../fonts/icomoon_blocs/icomoon.svg?tx1ev2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

//ICOMOON FAQ

@font-face {
  font-family: 'icomoon_faq';
  src:  url('../fonts/icomoon_faq/icomoon.eot?tsvgxy');
  src:  url('../fonts/icomoon_faq/icomoon.eot?tsvgxy#iefix') format('embedded-opentype'),
    url('../fonts/icomoon_faq/icomoon.ttf?tsvgxy') format('truetype'),
    url('../fonts/icomoon_faq/icomoon.woff?tsvgxy') format('woff'),
    url('../fonts/icomoon_faq/icomoon.svg?tsvgxy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}